<template>
  <div>
    <div class="dashboard__top-line">
      <div class="dashboard__greetings">
        <div class="dashboard__greeting-header">
          {{ $t('dashboard.good_morning', { name: $auth.user().firstName }) }}
        </div>
        <div>
          {{ $t('dashboard.check_news') }}
        </div>
      </div>
      <div class="dashboard__top-buttons">
        <CButton
          color="primary-dark"
          shape="pill"
          class="dashboard-button dashboard-button--light"
          @click="goToDrafts"
        >
          {{ $t('dashboard.go_to_drafts') }}
        </CButton>
        <CButton
          color="primary-dark"
          shape="pill"
          class="text-white dashboard-button"
          @click="goToNewOfferForm"
        >
          {{ $t('companies.job_offers.index.new') }}
        </CButton>
      </div>
    </div>

    <div v-if="!loading" class="row dashboard-content">
      <div class="col-4">
        <Graph
          :data="jobOffersActivity"
          class="graph--dashboard"
        />
        <RecentFeedbacks :items="recentFeedbacks" />
      </div>
      <div class="col-4">
        <ExpireSoon :items="expireSoon" />
        <ActiveMatches :items="activeMatches" />
      </div>
      <div class="col-4">
        <LastEvents :items="activites" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Graph from '../elements/dashboard/Graph.vue';
import ActiveMatches from '../elements/dashboard/ActiveMatches.vue';
import ExpireSoon from '../elements/dashboard/ExpireSoon.vue';
import RecentFeedbacks from '../elements/dashboard/RecentFeedbacks.vue';
import LastEvents from '../elements/dashboard/LastEvents.vue';

export default {
  components: {
    Graph,
    ActiveMatches,
    ExpireSoon,
    RecentFeedbacks,
    LastEvents,
  },
  computed: {
    ...mapState({
      jobOffersActivity: state => state.dashboard.jobOffersActivity,
      recentFeedbacks: state => state.dashboard.recentFeedbacks,
      expireSoon: state => state.dashboard.expireSoon,
      activeMatches: state => state.dashboard.activeMatches,
      activites: state => state.notifications.notifications,
      loading: state => state.dashboard.loading,
    }),
  },
  created() {
    this.$store.dispatch('dashboard/getData');
  },
  methods: {
    goToNewOfferForm() {
      this.$router.push({ name: 'add_job_offer' });
    },
    goToDrafts() {
      this.$store.commit('jobOffers/setCurrentTab', 'drafts');
      this.$router.push({ name: 'job_offers' });
    },
  },
};
</script>

<style lang="scss" scoped>
  .dashboard__top-line {
    display: flex;
    justify-content: space-between;
  }

  .dashboard__greetings {
    margin-bottom: 40px;
  }

  .dashboard__greeting-header {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .dashboard-button {
    border-radius: 8px;
    box-shadow: none;
    margin: 0 5px;

    &--light {
      background: #fff;
      color: #70738E;
    }
  }

</style>

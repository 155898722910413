import _ from 'lodash';
import moment from 'moment';

export default {
  methods: {
    formattedTime(time) {
      if (_.isNil(time)) {
        return;
      }

      if (moment().isSame(time, 'day')) {
        return moment(time).format('HH:mm')
      }

      return moment(time).format('HH:mm DD-MM-YY');
    },
  },
};

<template>
  <div class="offer-summary__wrapper">
    <span>{{ $t('companies.job_offers.show.offer_summary') }}</span>
    <div class="offer-summary__details">
      <div class="offer-summary__item offer-summary__item--multiple">
        {{ $t('companies.job_offers.show.salary_range') }}:
        <div class="offer-summary__item" v-for="item in jobOffer.employmentTypes" :key="item.id">
          <div class="offer-summary__value">{{ `${item.name}: ${item.grossSalaryMin} - ${item.grossSalaryMax}` }}</div>
        </div>
      </div>
      <div class="offer-summary__item">
        {{ $t('companies.job_offers.show.location') }}:
        <div class="offer-summary__value">{{ jobOffer.location.name }}</div>
      </div>
      <div class="offer-summary__item offer-summary__item--editable">
        <div v-if="!recruiterEdition" class="offer-summary__text">
          {{ $t('companies.job_offers.show.recruiter') }}:
          <div class="offer-summary__value">{{ jobOffer.recruiter.name }}</div>
        </div>
        <CustomSelect
          v-else
          :options="recruiters"
          :value="selectedRecruiter"
          displayName="fullName"
          class="offer-summary__select"
          @select="selectRecruiter($event)"
          @close="hideRecruiterEdition"
        />
        <CButton
          v-if="!recruiterEdition"
          class="offer-summary__button"
          @click="showRecruiterEdition()"
        >
          {{ $t('companies.job_offers.show.change') }}
      </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import CustomSelect from '../custom/CustomSelect.vue';

export default {
  data() {
    return {
      recruiterEdition: false,
    };
  },
  components: {
    CustomSelect,
  },
  props: {
    jobOffer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      recruiters: state => state.teamMembers.confirmedList,
    }),
    selectedRecruiter() {
      return _.find(this.recruiters, { id: this.jobOffer.recruiter.id });
    },
  },
  async created() {
    if (_.isEmpty(this.recruiters)) {
      await this.$store.dispatch('teamMembers/list');
    }
  },
  methods: {
    showRecruiterEdition() {
      this.recruiterEdition = true;
    },
    selectRecruiter(selectedRecruiter) {
      this.$store.dispatch('jobOffers/update', { id: this.jobOffer.id, recruiterId: selectedRecruiter.id });
    },
    hideRecruiterEdition() {
      this.recruiterEdition = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .offer-summary__details {
    border: 1px solid #E5E5E5;
    margin-top: 10px;
    padding: 20px 30px;
  }

  .offer-summary__item {
    display: flex;
    margin-bottom: 1rem;

    &--multiple {
      display: block;
    }

    &--editable {
      justify-content: space-between;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .offer-summary__text {
    display: flex;
  }

  .offer-summary__value {
    font-weight: bold;
    margin-left: 5px;
  }

  .offer-summary__select {
    margin-top: -15px;
    width: 100%;
  }

  .offer-summary__button {
    border: none;
    color: #FF7252;
    font-size: 12px;
    margin: -5px 0 0 0;
    text-decoration: underline;

    &:focus {
      box-shadow: none;
    }
  }
</style>

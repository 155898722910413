<template>
  <div class="active-matches__wrapper">
    <div class="active-matches__title">
      <span>{{ $t('dashboard.active_matches') }}</span>  
      <CButton
        class="active-matches__button"
        @click="showAll"
      >
        {{ $t('dashboard.show_all') }}
        &gt;
      </CButton>
    </div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="active-matches__item"
      @click="goToJobOffer(item.id)"
    >
      <div class="active-matches__matches-count">
        {{ `${item.ongoingRecruitmentProcessesCount} ${$t('dashboard.active')}` }}
      </div>
      <div class="active-matches__position">
        {{ item.position }}
      </div>
      <div class="active-matches__location">
        {{ item.location }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    showAll() {
      this.$router.push({ name: 'job_offers' });
    },
    goToJobOffer(id) {
      this.$router.push({ name: 'job_offer', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
  .active-matches__wrapper {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    min-height: 334px;
    align-items: flex-start;
    padding: 20px 25px;
  }

  .active-matches__title {
    align-items: baseline;
    color: #3D405B;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }

  .active-matches__button {
    box-shadow: none;
    padding: 0;

    &:active {
      transform: translateY(2px);
    }
  }

  .active-matches__item {
    border: 1px solid #E8E8EA;
    box-shadow: 0px 0px 6px rgba(61, 64, 91, 0.15);
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px 15px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  .active-matches__matches-count {
    color: #539B7B;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .active-matches__location {
    font-size: 11px;
  }
</style>

<template>
  <div class="secondary-topbar-wrapper" @click="goBack">
    <span class="secondary-topbar-button">
      &lt;
      {{ $t('common.back') }}
      <!-- {{ $t(`layouts.sections.${path}`) }} -->
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    path() {
      return this.$route.name;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
  .secondary-topbar-wrapper {
    border-bottom: 1px solid #E5E5E5;
    margin: -80px -150px 50px -150px;
    padding: 20px 150px;
  }

  .secondary-topbar-button {
    color: #77798C;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: #3D405B;
      font-weight: 500;
    }
  }
</style>

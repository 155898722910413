<template>
  <div>
    <TopBar />
    <CustomToast />
    <div class="main-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import TopBar from '../elements/layouts/TopBar.vue';
import CustomToast from '../elements/custom/CustomToast.vue';

export default {
  components: {
    TopBar,
    CustomToast,
  },
  created() {
    this.$store.commit('toaster/clear');
  },
};
</script>

<style lang="scss">
  body {
    height: 100vh;
  }

  .main-wrapper {
    padding: 80px 150px;
  }

  @media print {
    .main-wrapper {
      padding: 20px;
    }
  }
</style>

<template>
  <div class="header-wrapper">
    <div class="header-position">{{ position }}</div>
    <div class="header-details" @click="$emit('showDetails')">
      {{ $t('companies.job_offers.show.see_details') }}
    </div>
    <CButton
      v-if="isPublished"
      color="secondary-light"
      class="text-white header-archive-button"
      @click="$emit('closeOffer')"
    >
      {{ $t('companies.job_offers.show.close_offer') }}
    </CButton>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      required: true,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .header-wrapper {
    align-items: center;
    background: #53629B;
    border-radius: 8px;
    color: white;
    display: flex;
    margin-bottom: 50px;
    padding: 20px 30px;
  }

  .header-position {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
  }

  .header-details {
    background: url('../../../assets/images/details.svg') no-repeat 0 5px;
    cursor: pointer;
    margin: 0 25px 0 125px;
    padding: 0 15px;
  }

  .header-archive-button {
    border-radius: 12px;
  }
</style>

<template>
  <div class="graph__wrapper">
    <div class="graph__header">
      <div class="graph__name">
        {{ $t('dashboard.job_offers_activity') }}
      </div>
      <div>
        {{ period }}
      </div>
    </div>

    <BarChart
      :x="dataKeys"
      :y="dataValues"
      class="graph__content"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import BarChart from '../custom/BarChart.vue';

export default {
  components: {
    BarChart,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    period() {
      if (this.dataKeys && this.dataKeys.length > 0) {
        return `${moment(this.dataKeys[0]).format('DD.MM.YYYY')} - ${moment(this.dataKeys[this.dataKeys.length - 1]).format('DD.MM.YYYY')}`;
      }

      return null;
    },
    dataKeys() {
      return _.keys(this.data);
    },
    dataValues() {
      return _.values(this.data);
    },
  }
};
</script>

<style lang="scss" scoped>
  .graph__wrapper {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    margin-bottom: 30px;
    padding: 20px 25px;
  }

  .graph__header {
    margin-bottom: 30px;
  }

  .graph__name {
    font-weight: bold;
  }

  .graph__content {
    height: 220px;
  }
</style>

<template>
  <CButton
    shape="pill"
    :disabled="disabled"
    class="select-button-wrapper"
    :class="dynamicClass"
    @click="toggle"
  >
    {{ label }}
    <slot />
  </CButton>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    dynamicClass() {
      if (this.disabled) {
        return 'select-button-disabled';
      }

      return this.checked ? 'select-button-checked' : 'select-button-unchecked';
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style lang="scss" scoped>
  .select-button--employment-types {
    &.select-button-wrapper {
      font-size: 14px;
      padding: 5px 15px;
    }

    &.select-button-checked {
      padding-left: 25px;
    }
  }

  .select-button-wrapper {
    border: 1px solid #70738E;
    border-radius: 8px;
    font-size: 12px;
    padding: 3px 15px;
  }

  .select-button-unchecked {
    background: transparent;
    color: #70738E;
  }

  .select-button-checked {
    background: url('../../../assets/images/check.svg') no-repeat 10px #70738E;
    color: #fff;
    padding-left: 25px;
  }

  .select-button-disabled {
    background: #70738E;
    color: #fff;
  }

  button[disabled] {
    background-color: #70738E;
    pointer-events: none;
    opacity: 1;
  }
</style>

<template>
  <div v-if="!loading">
    <CButton
      class="text-white offers-button"
      shape="pill"
      @click="goToNewOfferForm"
    >
      {{ $t('companies.job_offers.index.new') }}
    </CButton>
    <NavBar :items="navBarItems" :selected="currentTab" @selected="changeTab" class="underline-navbar" />
    <Table :items="currentJobOffers" @showItem="showJobOffer($event)" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import NavBar from '../elements/custom/NavBar.vue'
import Table from '../elements/job_offers/Table.vue'

export default {
  components: {
    NavBar,
    Table
  },
  data() {
    return {
      fields: {
        published: [
          { key: 'position', label: this.$t('companies.job_offers.fields.position') },
          { key: 'location', label: this.$t('companies.job_offers.fields.location') },
          { key: 'ongoingRecruitmentProcessesCount', label: this.$t('companies.job_offers.fields.matches') },
          { key: 'acceptedRecruitmentProcessesCount', label: this.$t('companies.job_offers.fields.processes') },
          { key: 'publishedAt', label: this.$t('companies.job_offers.fields.published_at') },
          { key: 'recruiterName', label: this.$t('companies.job_offers.fields.recruiter') },
          { key: 'showDetails', label: '', _style: 'width:1%', sorter: false, filter: false },
        ],
        drafts: [
          { key: 'position', label: this.$t('companies.job_offers.fields.position') },
          { key: 'location', label: this.$t('companies.job_offers.fields.location') },
          { key: 'updatedAt', label: this.$t('companies.job_offers.fields.updated_at') },
          { key: 'recruiterName', label: this.$t('companies.job_offers.fields.recruiter') },
          { key: 'showDetails', label: '', _style: 'width:1%', sorter: false, filter: false },
        ],
        archived: [
          { key: 'position', label: this.$t('companies.job_offers.fields.position') },
          { key: 'location', label: this.$t('companies.job_offers.fields.location') },
          { key: 'ongoingRecruitmentProcessesCount', label: this.$t('companies.job_offers.fields.matches') },
          { key: 'acceptedRecruitmentProcessesCount', label: this.$t('companies.job_offers.fields.processes') },
          { key: 'publishedAt', label: this.$t('companies.job_offers.fields.published_at') },
          { key: 'archivedAt', label: this.$t('companies.job_offers.fields.archived_at') },
          { key: 'recruiterName', label: this.$t('companies.job_offers.fields.recruiter') },
          { key: 'showDetails', label: '', _style: 'width:1%', sorter: false, filter: false },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      jobOffers: state => state.jobOffers.jobOffers,
      currentTab: state => state.jobOffers.currentTab,
      loading: state => state.jobOffers.loading,
    }),
    jobOffersTypes() {
      return _.keys(this.jobOffers);
    },
    navBarItems() {
      return _.map(this.jobOffersTypes, (type) => (
        { key: type, count: _.size(this.jobOffers[type]) }
      ));
    },
    currentJobOffers() {
      return { items: this.jobOffers[this.currentTab], fields: this.fields[this.currentTab] };
    },
  },
  async created() {
    await this.getJobOffers();
    this.setInitialTab();
  },
  methods: {
    async getJobOffers() {
      await this.$store.dispatch('jobOffers/index');
    },
    setInitialTab() {
      if (this.currentTab) {
        return;
      }

      this.changeTab(this.jobOffersTypes[0]);
    },
    changeTab(selected) {
      this.$store.commit('jobOffers/setCurrentTab', selected);
    },
    goToNewOfferForm() {
      this.$router.push({ name: 'add_job_offer' });
    },
    showJobOffer(jobOffer) {
      if (jobOffer.state === 'draft') {
        this.$router.push({ name: 'edit_job_offer', params: { id: jobOffer.id } });
      } else {
        this.$router.push({ name: 'job_offer', params: { id: jobOffer.id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .offers-button {
    background: #3D405B;
    border-radius: 12px;
    margin-bottom: 30px;
  }
</style>

<template>
  <div>
    <NavBar
      :items="[{ key: 'requirements' }]"
      :show-counter="false"
      class="border-navbar requirements-nav-bar"
    />
    <div class="row requirements-wrapper">
      <div class="col-4 requirements-col">
        <p>{{ $t('companies.job_offers.job_offer_form.required_skills.label') }}</p>
        <div v-if="errors.requiredJobOfferSkillIds" class="requirements-errors">
          {{ errors.requiredJobOfferSkillIds.join(', ') }}
        </div>
        <span>{{ $t('companies.job_offers.job_offer_form.required_skills.description') }}</span>
        <div class="requirements-required">
          <CustomInputSelect
            v-if="skills"
            v-model="newRequiredSkill.name"
            name="requiredSkillName"
            :options="skills"
          />
          <div class="row">
            <CSelect
              v-if="predefinedRequiredSkill && predefinedRequiredSkill.proficiencyType === 'tier'"
              :options="predefinedRequiredSkill.tiers.map(a => a.name)"
              :value="newRequiredSkill.tierId"
              class="requirements-required-tiers"
              @update:value="selectSkillTier($event)"
            />
            <CustomInput
              v-else
              v-model="newRequiredSkill.years"
              name="requiredSkillYears"
              type="number"
              min="0"
              max="100"
              :placeholder="$tc('companies.job_offers.job_offer_form.years', 2)"
              class="col-4 requirements-skill-input"
            />
            <SelectButton
              :label="$t('companies.job_offers.job_offer_form.significant')"
              :checked="newRequiredSkill.significant"
              class="col-5 requirements-required-significant"
              @toggle="newRequiredSkill.significant = !newRequiredSkill.significant"
            >
              <img v-if="newRequiredSkill.significant" src="../../../assets/images/diamond-white.svg" class="requirements-diamond-icon" />
              <img v-else src="../../../assets/images/diamond-color.svg" class="requirements-diamond-icon" />
            </SelectButton>
            <CButton
              shape="pill"
              color="primary-dark"
              @click="addRequireSkill"
              class="col-2 text-white requirements-button"
            >
              {{ $t('companies.job_offers.job_offer_form.add') }}
            </CButton>
          </div>
        </div>
        <div class="row requirements-required-skills">
          <SkillButton
            v-for="(item, index) in requiredSkills"
            :key="index"
            :skill="item"
            @removeSkill="removeRequiredSkill(index)"
            @toggleSignificant="toggleRequiredSkillSignificant(item, index)"
          />
        </div>
      </div>
      <div class="col-4 requirements-col">
        <p>{{ $t('companies.job_offers.job_offer_form.desirable_skills.label') }}</p>
        <span>{{ $t('companies.job_offers.job_offer_form.desirable_skills.description') }}</span>
        <div class="row requirements-desirable">
          <CustomInputSelect
            v-if="skills"
            v-model="newDesirableSkill.name"
            name="desirableSkillName"
            :options="skills"
            class="col-9 requirements-skill-input"
          />
          <CButton
            color="primary-dark"
            shape="pill"
            class="col-2 text-white requirements-button"
            @click="addDesirableSkill"
          >
            {{ $t('companies.job_offers.job_offer_form.add') }}
          </CButton>
        </div>
        <div class="row requirements-desirable-skills">
          <SkillButton
            v-for="(item, index) in desirableSkills"
            :key="index"
            :skill="item"
            :skill-required="false"
            @removeSkill="removeDesirableSkill(index)"
          />
        </div>
      </div>
      <div class="col-4 requirements-col">
        <p>{{ $t('companies.job_offers.job_offer_form.recruiter.label') }}</p>
        <span>{{ $t('companies.job_offers.job_offer_form.recruiter.description') }}</span>
        <CustomSelect
          v-if="recruiters"
          :placeholder="$t('common.select_from_list')"
          :options="recruiters"
          :value="selectedRecruiter"
          displayName="fullName"
          :errors="errors.recruiter"
          class="requirements-select"
          @select="selectRecruiter($event)"
          @open="clearRecruiterSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import NavBar from '../custom/NavBar.vue';
import SelectButton from '../custom/SelectButton.vue';
import CustomInput from '../custom/CustomInput.vue';
import CustomSelect from '../custom/CustomSelect.vue';
import CustomInputSelect from '../custom/CustomInputSelect.vue';
import SkillButton from '../custom/SkillButton.vue';

const { mapFields } = createHelpers({
  getterType: 'forms/jobOffer/getField',
  mutationType: 'forms/jobOffer/updateField',
});

export default {
  components: {
    NavBar,
    SelectButton,
    CustomInput,
    CustomSelect,
    CustomInputSelect,
    SkillButton,
  },
  data() {
    return {
      newDesirableSkill: {},
      newRequiredSkill: { significant: false },
    };
  },
  computed: {
    ...mapFields([
      'form.requiredSkills',
      'form.desirableSkills',
      'form.recruiterId',
    ]),
    ...mapState({
      errors: state => state.forms.jobOffer.errors,
      success: state => state.forms.jobOffer.success,
      loading: state => state.forms.jobOffer.loading,
      teamList: state => state.teamMembers.confirmedList,
      skills: state => state.jobOffers.offerOptions.skills,
    }),
    predefinedRequiredSkill() {
      if (this.newRequiredSkill.name) {
        return _.find(this.skills, (skill) => { return skill.name.toLowerCase() === this.newRequiredSkill.name.replace(/\s+/g, " ").trim().toLowerCase(); });
      }

      return;
    },
    recruiters() {
      return this.teamList;
    },
    selectedRecruiter() {
      return _.find(this.recruiters, { id: this.recruiterId });
    },
  },
  async created() {
    if (_.isEmpty(this.teamList)) {
      await this.$store.dispatch('teamMembers/list');
    }
  },
  watch: {
    predefinedRequiredSkill(newValue, oldValue) {
      if (!oldValue && newValue && newValue.proficiencyType === 'tier') {
        this.selectDefaultTier();
      }
    },
  },
  methods: {
    addDesirableSkill() {
      if (_.isEmpty(this.newDesirableSkill.name)) {
        return;
      }

      this.desirableSkills.unshift({ name: this.newDesirableSkill.name });

      this.newDesirableSkill = {};
    },
    removeDesirableSkill(index) {
      this.desirableSkills.splice(index, 1);
    },
    selectDefaultTier() {
      this.newRequiredSkill.tierName = this.predefinedRequiredSkill.tiers[0].name;
      this.newRequiredSkill.tierId = this.predefinedRequiredSkill.tiers[0].id;
    },
    selectSkillTier(name) {
      this.newRequiredSkill.tierName = name;
      this.newRequiredSkill.tierId = _.find(this.predefinedRequiredSkill.tiers, { name: name }).id;
    },
    addRequireSkill() {
      if (_.isEmpty(this.newRequiredSkill.name)) {
        return;
      }

      this.requiredSkills.unshift(this.newRequiredSkill);
      this.newRequiredSkill = { significant: false };
    },
    removeRequiredSkill(index) {
      this.requiredSkills.splice(index, 1);
    },
    toggleRequiredSkillSignificant(item, index) {
      this.requiredSkills[index].significant = !item.significant;
    },
    selectRecruiter(recruiter) {
      this.recruiterId = recruiter.id;
    },
    clearRecruiterSelection() {
      if (this.recruiterId) {
        this.recruiterId = null;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin-left: 0;
  }

  .requirements-wrapper {
    margin: 0 -15px 60px -15px;
  }

  .requirements-nav-bar {
    margin: 30px 0;
  }

  .requirements-col {
    text-align: left;

    p {
      margin-bottom: 0;
    }

    span {
      color: #C4C4C4;
      font-size: 12px;
    }
  }

  .requirements-required {
    border-bottom: 1px solid #E5E5E5;
    margin: 20px 0;
    padding-bottom: 25px;
  }

  .requirements-desirable,
  .requirements-desirable-skills {
    margin-top: 20px;
    margin-left: 0;
  }

  .requirements-required-skills,
  .requirements-desirable-skills {
    align-items: flex-start;
  }

  .requirements-required-significant {
    height: 34px;
  }

  .requirements-required-tiers {
    margin: 0 10px 0 0;
  }

  .requirements-skill-input {
    height: 34px;
    padding-left: 0;
  }

  .requirements-button {
    border-radius: 8px;
    height: 34px;
    margin-left: 10px;
    padding: 0 12px;
  }

  .requirements-select {
    padding-bottom: 20px;
    margin-top: 30px;
  }

  .requirements-diamond-icon {
    padding-left: 3px;
  }

  .requirements-errors {
    color: #FF3000;
    font-size: 12px;
  }
</style>

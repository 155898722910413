<template>
  <Form />
</template>

<script>
import Form from '../elements/job_offer_form/Form.vue';

export default {
  components: {
    Form,
  },
  async created() {
    await this.$store.dispatch('forms/jobOffer/fetchJobOfferForEdition', this.$route.params.id);
  },
};
</script>

<template>
  <div
    class="conversation__wrapper"
    :class="[{ 'conversation__wrapper--opened': isOpened }, { 'conversation__wrapper--header': isHeader }]"
  >
    <div class="conversation__icon">
      {{ conversation.fitnessPercentage }}%
    </div>
    <div class="conversation__content">
      <div class="conversation__first-line">
        <div
          class="conversation__full-name"
          :class="{ 'conversation__full-name--unread': conversation.unread & !isHeader }"
        >
          {{ conversation.employeeFullName }}
        </div>
        <div class="conversation__date">
          {{ formattedTime(conversation.lastMessageSentAt) }}
        </div>
      </div>
      <div class="conversation__second-line">
        <div class="conversation__position">
          {{ conversation.position }}
        </div>
        <div
          v-if="conversation.unread & !isHeader"
          class="conversation__unread-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TimeFormatter from '../../../mixins/time_formatter';

export default {
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [TimeFormatter],
};
</script>

<style lang="scss" scoped>
  .conversation__wrapper {
    border-bottom: 1px solid #F7F7F9;
    display: flex;
    cursor: pointer;
    padding: 10px 50px 20px 50px;
    margin: 0 -50px;

    &:hover {
      background-color: #FAFAFE;
    }

    &--opened {
      background-color: #FAFAFE;
      border-left: 4px solid #3D405B;
      padding-left: 46px;
    }
  }

  .conversation__icon {
    align-items: center;
    background: #76B097;
    border-radius: 100%;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  .conversation__content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
  }

  .conversation__first-line,
  .conversation__second-line {
    display: flex;
    justify-content: space-between;
  }

  .conversation__unread-icon {
    background-color: #FE461C;
    border-radius: 50%;
    margin-top: 3px;
    height: 16px;
    width: 16px;
  }

  .conversation__full-name {
    font-size: 16px;
    font-weight: 700;

    &--unread {
      font-weight: 900;
    }
  }

  .conversation__date {
    align-self: center;
    font-size: 12px;
  }

  .conversation__wrapper--header {
    background-color: #FAFAFE;
    cursor: auto;
    margin: 0;
    padding: 25px;

    .conversation__date {
      display: none;
    }
  }
</style>

<template>
  <CButton
    shape="pill"
    :disabled="disabled"
    class="skill-button-wrapper"
  >
    <div
      class="skill-button-name"
      :class="dynamicClass"
      @click="clickOnName"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div>{{ skill.name }}</div>
    </div>
    <div v-if="skillRequired" class="skill-button-years">
      <div v-if="skill.years">
        {{ `${skill.years} ${$tc('companies.job_offers.job_offer_form.years', parseInt(skill.years))}` }}
      </div>
      <div v-else-if="skill.tierId">
        {{ skill.tierName }}
      </div>
      <div v-else>-</div>
    </div>
    <div
      v-if="skillRequired"
      class="skill-button-significant"
      :class="skill.significant ? 'skill-button-significant-checked' : 'skill-button-significant-unchecked'"
      @click="clickOnSignificant"
    >
      <img v-if="skill.significant" class="skill-button-img" src="../../../assets/images/diamond-white.svg" />
      <img v-else class="skill-button-img" src="../../../assets/images/diamond-color.svg" />
    </div>
  </CButton>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      hover: false,
    };
  },
  props: {
    skill: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    skillRequired: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dynamicClass() {
      if (this.disabled) {
        return 'select-button-disabled';
      }

      return this.hover ? 'skill-button-delete' : 'skill-button-checked';
    },
  },
  methods: {
    clickOnName() {
      if (this.disabled) {
        return;
      }

      this.$emit('removeSkill');
    },
    clickOnSignificant() {
      if (this.disabled || !this.skillRequired) {
        return;
      }

      this.$emit('toggleSignificant');
    },
  },
};
</script>

<style lang="scss" scoped>
  .skill-button-wrapper {
    align-items: stretch;
    border: 1px solid #70738E;
    border-radius: 8px;
    display: flex;
    font-size: 12px;
    margin: 3px;
    padding: 0;
    overflow: hidden;
  }

  .skill-button-checked {
    background: url('../../../assets/images/check.svg') no-repeat 10px 10px #70738E;
  }

  .skill-button-delete {
    background: url('../../../assets/images/delete.svg') no-repeat 10px 10px #3D405B;
  }

  .select-button-disabled {
    background: #70738E;
    color: #fff;
    padding-left: 10px !important;
  }

  .skill-button-name {
    align-items: center;
    color: white;
    display: flex;
    padding: 5px 10px 5px 25px;
    max-width: 200px;
  }

  .skill-button-years {
    align-items: center;
    border-right: 1px solid #70738E;
    color: #70738E;
    cursor: auto;
    display: flex;
    padding: 5px;
  }

  .skill-button-significant {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  .skill-button-significant-checked {
    background: #70738E;
  }

  .skill-button-img {
    width: 12px;
    height: 12px;
  }

  button[disabled] {
    pointer-events: none;
    opacity: 1;
  }
</style>

import Vue from 'vue';
import Vuex from 'vuex';

import forms from './forms/index';
import jobOffers from './job_offers';
import teamMembers from './team_members';
import recruitmentProcesses from './recruitment_processes';
import dashboard from './dashboard';
import messages from './messages';
import notifications from './notifications';
import toaster from './toaster';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    forms,
    jobOffers,
    teamMembers,
    recruitmentProcesses,
    dashboard,
    messages,
    notifications,
    toaster,
  }
});

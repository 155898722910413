import _ from 'lodash';
import { basicFormAction, basicFormStore, basicFormState } from './basic';
import teamMembersRepository from '../../repositories/team_members_repository';

const formState = () => ({
  form: {
    firstName: '',
    lastName: '',
    email: '',
    role: 'recruiter',
  },
});

const mutations = {
  init(state) {
    _.assign(state, basicFormState(), formState());
  },
};

const actions = {
  async invite(context) {
    await basicFormAction(context, async () => {
      await teamMembersRepository.invite(
        {
          firstName: context.state.form.firstName,
          lastName: context.state.form.lastName,
          email: context.state.form.email,
          role: context.state.form.role,
        },
      );
    });
    if (context.state.success) {
      context.commit('init');
    }
  },
};

export default _.defaultsDeep({
  state: basicFormState(),
  mutations,
  actions,
}, basicFormStore);

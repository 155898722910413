const getters = {};

const mutations = {
  set(state, params) {
    state.messages = params.messages;
    state.autoHide = params.autoHide;
    state.show = true;
    state.fixedToasts++;
  },
  clear(state) {
    state.messages = null;
    state.autoHide = null;
    state.show = false;
  },
};

const actions = {
  async display(context, params) {
    context.commit('clear');
    context.commit('set', params);
  },
};

export default {
  namespaced: true,
  state: {
    messages: null,
    show: false,
    autoHide: null,
    fixedToasts: 0,
  },
  getters,
  mutations,
  actions,
};

import dashboardRepository from '../repositories/dashboard_repository';

const getters = {};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setJobOffersActivity(state, jobOffersActivity) {
    state.jobOffersActivity = jobOffersActivity;
  },
  setRecentFeedbacks(state, recentFeedbacks) {
    state.recentFeedbacks = recentFeedbacks;
  },
  setExpireSoon(state, expireSoon) {
    state.expireSoon = expireSoon;
  },
  setActiveMatches(state, activeMatches) {
    state.activeMatches = activeMatches;
  },
};

const actions = {
  async getData(context) {
    context.commit('setLoading', true);

    const jobOffersActivity = await dashboardRepository.getJobOffersActivity();
    context.commit('setJobOffersActivity', jobOffersActivity);

    const recentFeedbacks  = await dashboardRepository.getRecentFeedbacks();
    context.commit('setRecentFeedbacks', recentFeedbacks);

    const expireSoon  = await dashboardRepository.getExpireSoon();
    context.commit('setExpireSoon', expireSoon);

    const activeMatches  = await dashboardRepository.getActiveMatches();
    context.commit('setActiveMatches', activeMatches);

    context.commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    loading: true,
    jobOffersActivity: [],
    recentFeedbacks: [],
    expireSoon: [],
    activeMatches: []
  },
  getters,
  mutations,
  actions,
};

<template>
  <ChangePasswordForm />
</template>

<script>
import ChangePasswordForm from '../auth/ChangePasswordForm.vue'

export default {
  components: { ChangePasswordForm },
};
</script>

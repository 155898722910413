import recruitmentProcessRepository from '../repositories/recruitment_processes_repository';
import recruitmentProcessNotesRepository from '../repositories/recruitment_process_notes_repository';
const getters = {};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setRecruitmentProcess(state, recruitmentProcess) {
    state.recruitmentProcess = recruitmentProcess;
  },
  setRecruitmentProcessNotes(state, notes) {
    state.recruitmentProcess.notes = notes;
  },
  setRecruitmentProcesses(state, recruitmentProcesses) {
    state.recruitmentProcesses = recruitmentProcesses;
  },
};

const actions = {
  async index(context, jobOfferId) {
    context.commit('setLoading', true);

    const recruitmentProcesses = await recruitmentProcessRepository.index(jobOfferId);

    context.commit('setRecruitmentProcesses', recruitmentProcesses);
    context.commit('setLoading', false);
  },
  async show(context, { id }) {
    context.commit('setLoading', true);

    const recruitmentProcess = await recruitmentProcessRepository.show(id);

    context.commit('setRecruitmentProcess', recruitmentProcess);
    context.commit('setLoading', false);
  },
  async resume(context, { id }) {
    const response = await recruitmentProcessRepository.resume(id);

    return response;
  },
  async accept(context, { id }) {
    context.commit('setLoading', true);
    const response = await recruitmentProcessRepository.accept(id);
    context.commit('setLoading', false);
    return response;
  },
  async reject(context, { id }) {
    context.commit('setLoading', true);
    const response = await recruitmentProcessRepository.reject(id);
    context.commit('setLoading', false);
    return response;
  },
  async setStatus(context, { id, content }) {
    const recrutimentProcess = await recruitmentProcessRepository.update(id, { employerStatus: content });

    context.commit('setRecruitmentProcess', recrutimentProcess);
  },
  async removeStatus(context, { id }) {
    const recrutimentProcess = await recruitmentProcessRepository.update(id, { employerStatus: null });

    context.commit('setRecruitmentProcess', recrutimentProcess);
  },
  async addNote(context, { id, content }) {
    const notes = await recruitmentProcessNotesRepository.create(id, { content });

    context.commit('setRecruitmentProcessNotes', notes);
  },
  async removeNote(context, { id, noteId }) {
    const notes = await recruitmentProcessNotesRepository.destroy(id, noteId);

    context.commit('setRecruitmentProcessNotes', notes);
  },
};

export default {
  namespaced: true,
  state: {
    recruitmentProcesses: {},
    recruitmentProcess: null,
    loading: true,
  },
  getters,
  mutations,
  actions,
};

import httpClient from '../utils/http_client';

export default {
  async getJobOffersActivity() {
    const response = await httpClient.get('/dashboard/job_offers_reach');

    return response.data;
  },
  async getRecentFeedbacks() {
    const response = await httpClient.get('/dashboard/recent_feedbacks');

    return response.data;
  },
  async getExpireSoon() {
    const response = await httpClient.get('/dashboard/recruitment_processes_to_expire');

    return response.data;
  },
  async getActiveMatches() {
    const response = await httpClient.get('/dashboard/job_offers_worklist');

    return response.data;
  },
};

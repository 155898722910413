<template>
  <CNavbar expandable="md" color="primary-dark" class="navbar-wrapper">
    <CNavbarBrand>
      <router-link to="/">
        <img src="../../../assets/images/logo.svg" class="navbar-logo" />
      </router-link>
    </CNavbarBrand>
    <CNavbarNav>
      <CNavItem
        :class="{'active': currentTab === 'dashboard'}"
        @click.native="goTo('dashboard')"
      >
        {{ $t('layouts.sections.dashboard') }}
      </CNavItem>
      <CNavItem
        :class="{'active': currentTab === 'job_offers'}"
        @click.native="goTo('job_offers')"
      >
        {{ $t('layouts.sections.job_offers') }}
      </CNavItem>
      <CNavItem
        :class="{'active': currentTab === 'messages'}"
        @click.native="goTo('messages')"
      >
        <div>{{ $t('layouts.sections.messages') }}</div>
        <div v-if="unreadConversations" class="navbar-indicator-icon" />
      </CNavItem>
    </CNavbarNav>

    <CNavbarNav class="ml-auto">
      <Notifications />
      <CDropdown
        nav
        :togglerText="recruiterFullName"
        placement="bottom-end"
        class="navbar-dropdown"
      >
        <CDropdownItem @click.native="goTo('settings')">{{ $t('layouts.sections.settings') }}</CDropdownItem>
        <CDropdownItem @click.native="logout">{{ $t('login.sign_out') }}</CDropdownItem>
      </CDropdown>
    </CNavbarNav>
  </CNavbar>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Notifications from './Notifications.vue';

export default {
  components: {
    Notifications,
  },
  computed: {
    ...mapState({
      unreadConversations: state => state.notifications.unreadConversations,
    }),
    recruiterFullName() {
      if (_.isUndefined(this.$auth.user().firstName) && _.isUndefined(this.$auth.user().lastName)) {
        return '';
      }
      if (this.$auth.user().role == "staff") {
        return `${this.$auth.user().firstName} ${this.$auth.user().lastName} @ ${this.$auth.user().companyName}`;
      } else {
        return `${this.$auth.user().firstName} ${this.$auth.user().lastName}`;
      }
    },
    currentTab() {
      return this.$route.name;
    },
  },
  created() {
    this.$store.dispatch('notifications/getConversationsStatus');
  },
  methods: {
    goTo(section) {
      if (this.$route.name === section) {
        return;
      }

      this.$router.push({ name: section });
    },
    async logout() {
      await this.$auth.logout();
      window.location = '/company/login';
    },
  },
};
</script>

<style lang="scss" scoped>
  .navbar-wrapper {
    height: 66px;

    .dropdown {
      padding-top: 11px;

      ::v-deep button.dropdown-toggle {
        box-shadow: none;
        color: #D9D9DF;
      }
    }

    .dropdown.navbar-dropdown {
      padding-top: 15px;
      margin: 0 10px 0 30px;
    }

    ::v-deep .nav-link {
      display: inline-flex;
    }
  }

  .navbar-brand {
    margin: 17px 52px 0 16px;
    padding: 6px 5px 24px 0px;
  }

  .navbar-logo {
    height: 30px;
  }

  .nav-item {
    padding: 15px 45px 0 0;
  }

  .navbar-indicator-icon {
    background-color: #FE461C;
    border-radius: 50%;
    margin: 7px;
    height: 10px;
    width: 10px;
  }
</style>

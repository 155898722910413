<template>
  <Form />
</template>

<script>
import Form from '../elements/job_offer_form/Form.vue';

export default {
  components: {
    Form
  },
};
</script>

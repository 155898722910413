import httpClient from '../utils/http_client';

export default {
  async index(jobOfferId) {
    const response = await httpClient.get(`/job_offers/${jobOfferId}/recruitment_processes`);

    return response.data;
  },

  async show(id) {
    const response = await httpClient.get(`/recruitment_processes/${id}`);

    return response.data;
  },

  async update(id, params) {
    const response = await httpClient.put(`/recruitment_processes/${id}`, params);

    return response.data;
  },

  async accept(id) {
    const response = await httpClient.post(`/recruitment_processes/${id}/accept`);

    return response.data;
  },

  async reject(id) {
    const response = await httpClient.post(`/recruitment_processes/${id}/reject`);

    return response.data;
  },

  async hire(id, params) {
    const response = await httpClient.put(`/recruitment_processes/${id}/hire`, params);

    return response.data;
  },

  async notHire(id, params) {
    const response = await httpClient.put(`/recruitment_processes/${id}/not_hire`, params);

    return response.data;
  },
  async resume(id) {
    const response = await httpClient.get(`/recruitment_processes/${id}/resume`, { responseType: 'blob' });

    return response.data;
  }
};

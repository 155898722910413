<template>
  <div>
    <EmptyLayout v-if="$route.meta.withoutLayout">
      <RouterView />
    </EmptyLayout>
    <MainLayout v-else>
      <RouterView />
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from './layouts/Main.vue';
import EmptyLayout from './layouts/Empty.vue';

export default {
  components: {
    MainLayout,
    EmptyLayout,
  },
  created() {
    window.EventBus.$on('unauthorized', () => {
      this.$router.push({ name: 'login' });
      this.$store.dispatch('toaster/display', {
        messages: [{ message: this.$t('login.session_expired') }],
        autoHide: 5000,
      });
    });
  },
};
</script>

<style lang="scss">
  @import '../assets/styles/custom';

  @media print {
    body {
      background-color: transparent;
    }

    button {
      visibility: hidden;
    }
  }
</style>

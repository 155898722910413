import jobOffersRepository from '../repositories/job_offers_repository';
import jobOfferNotesRepository from '../repositories/job_offer_notes_repository';

const getters = {};

const mutations = {
  set(state, jobOffers) {
    state.jobOffers = jobOffers;
  },
  setJobOffer(state, jobOffer) {
    state.jobOffer = jobOffer;
  },
  setJobOfferNotes(state, notes) {
    state.jobOffer.notes = notes;
  },
  setCurrentTab(state, currentTab) {
    state.currentTab = currentTab;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setOfferOptions(state, offerOptions) {
    state.offerOptions = offerOptions;
  },
};

const actions = {
  async index(context) {
    context.commit('setLoading', true);

    const jobOffers = await jobOffersRepository.index();

    context.commit('set', jobOffers);
    context.commit('setLoading', false);
  },
  async show(context, id) {
    context.commit('setLoading', true);

    const jobOffer = await jobOffersRepository.show(id);

    context.commit('setJobOffer', jobOffer);
    context.commit('setLoading', false);
  },
  async update(context, { id, recruiterId }) {
    const jobOffer = await jobOffersRepository.update({ id, recruiterId });

    context.commit('setJobOffer', jobOffer);
  },
  async archive(context, id) {
    context.commit('setLoading', true);

    const response = await jobOffersRepository.archive(id);
    if (response.status === 'success') {
      context.dispatch('show', id);
    }

    context.commit('setLoading', false);
    return response.notice;
  },
  async fetchOfferOptions(context) {
    const offerOptions = await jobOffersRepository.offerOptions();

    context.commit('setOfferOptions', offerOptions);
  },
  async addNote(context, { id, content }) {
    const notes = await jobOfferNotesRepository.create(id, { content });

    context.commit('setJobOfferNotes', notes);
  },
  async removeNote(context, { id, noteId }) {
    const notes = await jobOfferNotesRepository.destroy(id, noteId);

    context.commit('setJobOfferNotes', notes);
  },
};

export default {
  namespaced: true,
  state: {
    jobOffers: {},
    jobOffer: null,
    offerOptions: {},
    currentTab: null,
    loading: true,
  },
  getters,
  mutations,
  actions,
};

import teamMembersRepository from '../repositories/team_members_repository';

const getters = {};

const mutations = {
  set(state, list) {
    state.list = list;
  },
  setConfirmed(state, list) {
    state.confirmedList = list;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  async index(context) {
    context.commit('setLoading', true);

    const teamList = await teamMembersRepository.index();

    context.commit('set', teamList);
    context.commit('setLoading', false);
  },

  async list(context) {
    const teamList = await teamMembersRepository.list();
    context.commit('setConfirmed', teamList);
  },

  async deleteMember(context, id) {
    await teamMembersRepository.delete(id);
  },
};

export default {
  namespaced: true,
  state: {
    list: [],
    confirmedList: [],
    loading: true,
  },
  getters,
  mutations,
  actions,
};

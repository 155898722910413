<template>
  <div class="last-events__wrapper">
    <div class="last-events__header">
      {{ $t('dashboard.last_events') }}
    </div>
    <div class="last-events__content">
      <Notification
        v-for="(item, index) in displayItems"
        :key="index"
        :notification="item"
      />
    </div>
  </div>
</template>

<script>
import Notification from '../layouts/Notification';

export default {
  components: {
    Notification,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      maxNumberDisplayItems: 8,
    };
  },
  computed: {
    displayItems() {
      return _.slice(this.items, 0, this.maxNumberDisplayItems);
    },
  },
};
</script>

<style lang="scss" scoped>
  .last-events__wrapper {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    min-height: 822px;
  }

  .last-events__header {
    background-color: #539B7B;
    border-radius: 12px 12px 0 0;
    color: #fff;
    font-weight: bold;
    padding: 20px 25px;
  }
</style>

<template>
  <div class="accept-invitation-wrapper">
    <img src="../../../assets/images/logo.svg" class="login-logo" />

    <AcceptInvitationForm />
  </div>
</template>

<script>
import AcceptInvitationForm from '../../elements/auth/AcceptInvitationForm.vue';

export default {
  components: {
    AcceptInvitationForm,
  },
};
</script>

<style lang="scss" scoped>
  .accept-invitation-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 200px;
  }
</style>

<template>
  <div class="recruitment-process-wrapper">
    <SecondaryTopBar />
    <Header
      v-if="!loading"
      :recruitment-process="recruitmentProcess"
      :show-accepted-alert="showAcceptedAlert"
      @reject="reject"
      @accept="accept"
      @notHire="showNotHireModal = true"
      @hire="showHireModal = true"
      @closeAcceptedAlert="showAcceptedAlert = false"
    />

    <HireModal
      :showModal="showHireModal"
      @modalClosed="showHireModal = false"
    />

    <NotHireModal
      :showModal="showNotHireModal"
      @modalClosed="showNotHireModal = false"
    />

    <div v-if="!loading" class="row recruitment-process-details">
      <div class="col-4 recruitment-process__column">
        <div>
          <div class="recruitment-process-details__label">
            {{ $t('companies.match.summary') }}
            </div>
          <div class="recruitment-process-box">
            <div class="recruitment-process-details__line">
              {{ $t('companies.match.min_net_salary') }}:
              <span class="recruitment-process-box__value">
                {{ recruitmentProcess.salaryMin.toLocaleString().replace(',', ' ') }}
              </span>
            </div>
            <div v-if="recruitmentProcess.employeePhoneNumber" class="recruitment-process-details__line">
              {{ $t('companies.match.phone_number') }}:
              <span class="recruitment-process-box__value">
                {{ recruitmentProcess.employeePhoneNumber }}
              </span>
            </div>
            <div v-if="recruitmentProcess.employeeEmail" class="recruitment-process-details__line">
              {{ $t('companies.match.email') }}:
              <span class="recruitment-process-box__value">
                {{ recruitmentProcess.employeeEmail }}
              </span>
            </div>
            <div v-if="recruitmentProcess.employeeConversationId" class="recruitment-process-details__line">
              <CButton
                class="text-white recruitment-process-send-button mr-2"
                shape="pill"
                @click="goToConversation"
              >
                {{ $t('companies.match.write_message') }}
              </CButton>
              <CButton
                class="recruitment-process-resume-button"
                shape="pill"
                @click="downloadResume"
              >
                {{ $t('companies.match.download_resume') }}
              </CButton>
            </div>
            <div v-if="recruitmentProcess.recruiterFullName" class="recruitment-process-details__line">
              {{ $t('companies.match.recruiter') }}:
              <span class="recruitment-process-box__value">
                {{ recruitmentProcess.recruiterFullName }}
              </span>
            </div>
          </div>
        </div>
        <CustomFields
          :title="$t('companies.job_offer.fields.status')"
          :single="true"
          :items="status"
          @add="addStatus($event)"
          @remove="removeStatus"
          class="custom-fields--recruitment-process-status"
        />
        <CustomFields
          :items="recruitmentProcess.notes"
          :title="$t('companies.job_offers.notes')"
          @add="addNote($event)"
          @remove="removeNote($event)"
          class="custom-fields--recruitment-process-notes"
        />
      </div>
      <div class="col-4 recruitment-process__column">
        <div class="recruitment-process-details__label">
          {{ $t('companies.match.experience') }}
        </div>
        <div
          v-for="(item, index) in recruitmentProcess.jobExperiences"
          :key="index"
          class="recruitment-process-experience-box"
          :class="{'recruitment-process-experience-box--selected' : item.id === experienceId}"
          @click="setExperienceId(item.id)"
        >
          <div>{{ item.name }}</div>
          <div>{{ item.jobTitle }}</div>
          <div>
            <span class="recruitment-process-experience-box__dates">
              {{ item.from }} - {{ item.to }}
            </span>
            <span v-if="item.period" class="recruitment-process-experience-box__period">
              ({{ item.period }})
            </span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div>
          <div class="recruitment-process-details__label">
            {{ $t('companies.match.required_skills') }}
          </div>
          <div class="row recruitment-process-skill-container">
            <MatchSkill
              v-for="(item, index) in recruitmentProcess.requiredSkills"
              :considerMonths="true"
              :experienceId="experienceId"
              :key="index"
              :skill="item"
              class="recruitment-process-skill"
            />
          </div>
        </div>
        <div class="recruitment-process-subtitle">
          <div class="recruitment-process-details__label">
            {{ $t('companies.match.desirable_skills') }}
          </div>
          <div class="row recruitment-process-skill-container">
            <MatchSkill
              v-for="(item, index) in recruitmentProcess.desirableSkills"
              :experienceId="experienceId"
              :key="index"
              :skill="item"
              class="recruitment-process-skill"
            />
          </div>
        </div>
        <div class="recruitment-process-subtitle">
          <div class="recruitment-process-details__label">
            {{ $t('companies.match.additional_skills') }}
          </div>
          <div class="row recruitment-process-skill-container">
            <MatchSkill
              v-for="(item, index) in recruitmentProcess.additionalSkills"
              :experienceId="experienceId"
              :key="index"
              :skill="item"
              class="recruitment-process-skill"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import Header from '../elements/recruitment_process/Header.vue';
import SelectButton from '../elements/custom/SelectButton.vue';
import CustomFields from '../elements/custom/CustomFields.vue';
import MatchSkill from '../elements/recruitment_process/MatchSkill.vue';
import HireModal from '../elements/recruitment_process/HireModal.vue';
import NotHireModal from '../elements/recruitment_process/NotHireModal.vue';
import SecondaryTopBar from '../elements/layouts/SecondaryTopBar.vue';
import FileDownload from 'js-file-download';

export default {
  components: {
    Header,
    SelectButton,
    CustomFields,
    MatchSkill,
    HireModal,
    NotHireModal,
    SecondaryTopBar,
  },
  data () {
    return {
      showAcceptedAlert: false,
      showHireModal: false,
      showNotHireModal: false,
      experienceId: null,
    };
  },
  computed: {
    ...mapState({
      recruitmentProcess: state => state.recruitmentProcesses.recruitmentProcess,
      success: state => state.recruitmentProcesses.success,
      loading: state => state.recruitmentProcesses.loading,
    }),
    lowerState() {
      return _.toLower(this.recruitmentProcess.state);
    },
    status() {
      return this.recruitmentProcess.employerStatus ? [{ content: this.recruitmentProcess.employerStatus }] : [];
    },
  },
  async created() {
    await this.$store.dispatch('recruitmentProcesses/show', {
      id: this.$route.params.id
    });
  },
  methods: {
    async accept() {
      const response = await this.$store.dispatch('recruitmentProcesses/accept', {
        id: this.$route.params.id
      });

      if (response.status === 'success') {
        await this.$store.dispatch('recruitmentProcesses/show', {
          id: this.$route.params.id
        });
        this.showAcceptedAlert = true;
      }
    },
    async reject() {
      const response = await this.$store.dispatch('recruitmentProcesses/reject', {
        id: this.$route.params.id
      });

      if (response.status === 'success') {
        await this.$store.dispatch('recruitmentProcesses/show', {
          id: this.$route.params.id
        });
      }
    },
    async downloadResume() {
      const response = await this.$store.dispatch('recruitmentProcesses/resume', {
        id: this.$route.params.id
      });

      FileDownload(response, 'cv.pdf');
    },
    setExperienceId(value) {
      if (value ===  this.experienceId) {
        this.experienceId = null;
      } else {
        this.experienceId = value;
      }
    },
    async addNote(value) {
      await this.$store.dispatch('recruitmentProcesses/addNote', {
        id: this.$route.params.id,
        content: value,
      });
    },
    async removeNote(value) {
      await this.$store.dispatch('recruitmentProcesses/removeNote', {
        id: this.$route.params.id,
        noteId: value.item.id,
      });
    },
    addStatus(value) {
      this.$store.dispatch('recruitmentProcesses/setStatus', {
        id: this.$route.params.id,
        content: value,
      });
    },
    removeStatus() {
      this.$store.dispatch('recruitmentProcesses/removeStatus', {
        id: this.$route.params.id,
      });
    },
    goToConversation() {
      this.$router.push({ name: 'messages', params: { conversationId: this.recruitmentProcess.employeeConversationId } });
    },
  },
};
</script>

<style lang="scss" scoped>
  .recruitment-process-wrapper {
    width: 100%;
  }

  .recruitment-process-details {
    margin-top: 50px;
    text-align: left;

    &__label {
      font-weight: bold;
    }

    &__line {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .recruitment-process__column {
    padding-right: 30px;
  }

  .recruitment-process-box {
    border: 1px solid #E5E5E5;
    margin-top: 20px;
    padding: 20px;

    &__value {
      font-weight: bold;
      margin-left: 2px;
    }
  }

  .recruitment-process-subtitle {
    margin-top: 20px;
  }

  .recruitment-process-experience-box {
    border: 1px solid #E5E5E5;
    color: #C4C4C4;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    padding: 20px;

    div:first-child {
      color: black;
      font-size: 16px;
    }

    &--selected {
      border: 2px solid #70738E;
    }

    &__period {
      font-style: italic;
      white-space: nowrap;
    }

    &__dates {
      margin-right: 5px;
    }
  }

  .recruitment-process-skill-container {
    margin: 0px;
  }

  .recruitment-process-skill {
    margin: 10px 10px 0 0;
  }

  .recruitment-process-send-button {
    background: #3D405B url('../../assets/images/arrow_send-white.svg') no-repeat 15px;
    border-radius: 12px;
    margin-top: 10px;
    padding: 8px 15px 8px 40px;
  }

  .recruitment-process-resume-button {
    background: transparent url('../../assets/images/file-solid.svg') no-repeat 15px;
    background-size: 15px 15px;
    color: #3D405B;
    border-radius: 12px;
    border: solid 1px #3D405B;
    margin-top: 10px;
    padding: 8px 15px 8px 40px;
  }
</style>

<template>
  <form class="forgot-password-form-wrapper" @submit.prevent="submit">
    <CustomInput
      v-model="email"
      :label="$t('forgot_password.email.label')"
      :placeholder="$t('forgot_password.email.placeholder')"
      :required="true"
      class="underline-input forgot-password-form-input"
    />

    <CButton
      type="submit"
      color="primary-dark"
      shape="pill"
      :disabled="loading"
      class="forgot-password-form-button text-white"
    >
      {{ $t('forgot_password.reset') }}
    </CButton>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import CustomInput from '../../elements/custom/CustomInput.vue';

const { mapFields } = createHelpers({
  getterType: 'forms/forgotPassword/getField',
  mutationType: 'forms/forgotPassword/updateField',
});

export default {
  components: {
    CustomInput,
  },
  computed: {
    ...mapFields([
      'form.email',
    ]),
    ...mapState({
      errors: state => state.forms.forgotPassword.errors,
      loading: state => state.forms.forgotPassword.loading,
      success: state => state.forms.forgotPassword.success,
    }),
    returnUrl() {
      const path = this.$router.resolve({
        name: 'reset_password',
      }).href;

      return window.location.origin + path;
    },
  },
  watch: {
    errors(value) {
      if (value.length > 0) {
        this.$store.dispatch('toaster/display', {
          messages: [{ message: value.join(', ') }],
          autoHide: 5000,
        });
      }
    },
  },
  created() {
    this.$store.commit('forms/forgotPassword/init');
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }

      this.$store.dispatch('forms/forgotPassword/reset', { redirectUrl: this.returnUrl });
    },
  },
};
</script>

<style lang="scss" scoped>
  .forgot-password-form-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .forgot-password-form-input {
    margin-top: 20px;
  }

  .forgot-password-form-button {
    font-size: 18px;
    margin-top: 30px;
    padding: 8px 30px;
  }
</style>

<template>
  <div>
    <div class="custom-fields__title">
      <span>
        {{ title }}
      </span>
      <CButton
        v-if="!showNewDialog && (!single || items.length < 1)"
        class="custom-fields__add-button"
        @click="showDialog()"
      >
        + {{ $t('companies.job_offers.job_offer_form.add_new') }}
      </CButton>
    </div>
    <CustomTextArea
      v-if="showNewDialog"
      :content="content"
      @cancel="showNewDialog = false"
      @update="addNewItem($event)"
    />
    <CustomField
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :index="index"
      :allowEdit="allowEdit"
      @edit="editItem($event)"
      @remove="removeItem($event)"
    />
  </div>
</template>

<script>
import CustomTextArea from './CustomTextArea.vue';
import CustomField from './CustomField.vue';

export default {
  data() {
    return {
      showNewDialog: false,
      content: '',
    };
  },
  components: {
    CustomTextArea,
    CustomField,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showDialog() {
      this.showNewDialog = true;
      this.content = '';
    },
    addNewItem(newContent) {
      if (newContent.length > 0) {
        this.$emit('add', newContent);
        this.showNewDialog = false;
      }
    },
    editItem(value) {
      const index = value.index;
      const updatedContent = value.updatedContent;
      if (updatedContent.length > 0) {
        this.$emit('edit', { updatedContent, index });
      }
    },
    removeItem(value) {
      const item = value.item;
      const index = value.index;
      this.$emit('remove', { item, index });
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-fields__title {
    display: flex;
    justify-content: space-between;
  }

  .custom-fields__add-button {
    color: #FF7252;
    font-size: 12px;
    padding: 0;
    text-decoration: underline;

    &:focus {
      box-shadow: none;
    }
  }

  // custom classes achieved by calling e.g. <CustomFields class="custom-fields--job-offer-notes" />
  .custom-fields--requirements,
  .custom-fields--details {
    width: 83%;
  }

  .custom-fields--job-offer-notes,
  .custom-fields--recruitment-process-notes,
  .custom-fields--recruitment-process-status {
    margin-top: 50px;

    .custom-fields__title {
      font-weight: bold;
    }
  }
</style>

<template>
  <div class="expire-soon__wrapper">
    <div class="expire-soon__title">{{ $t('dashboard.expire_soon') }}</div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="expire-soon__item"
      @click="goToRecruitemtProcess(item.id)"
    >
      <div class="expire-soon__item__header">
        <span class="expire-soon__remaining-time">
          {{ item.timeLeft }}
          <span class="expire-soon__remaining-time--normal">
            {{ $t('dashboard.left') }}
          </span>
        </span>
        <span class="expire-soon__fitness-percentage">FS: {{ item.fitnessPercentage }}%</span>
      </div>
      <div class="expire-soon__position">
        {{ item.position }}
      </div>
      <div class="expire-soon__location">
        {{ item.locationName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToRecruitemtProcess(id) {
      this.$router.push({ name: 'recruitment_process', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
  .expire-soon__wrapper {
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 334px;
    margin-bottom: 30px;
    padding: 20px 25px;
  }

  .expire-soon__title {
    color: #3D405B;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .expire-soon__item {
    border: 1px solid #E8E8EA;
    box-shadow: 0px 0px 6px rgba(61, 64, 91, 0.15);
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px 15px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  .expire-soon__item__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  .expire-soon__remaining-time {
    color: #EA6447;
    font-weight: bold;
    font-size: 11px;

    &--normal {
      font-weight: normal;
    }
  }

  .expire-soon__fitness-percentage {
    color: #9D9D9D;
    font-size: 11px;
  }

  .expire-soon__location {
    font-size: 11px;
  }
</style>

import httpClient from '../utils/http_client';

export default {
  async index() {
    const response = await httpClient.get('/team_members');

    return response.data;
  },

  async list() {
    const response = await httpClient.get('/team_members/list');

    return response.data;
  },

  async invite(params) {
    const response = await httpClient.post('/team_members/invite', params);

    return response.data;
  },

  async acceptInvitation(params) {
    const response = await httpClient.put('/team_members/accept_invitation', params);

    return response.data;
  },

  async delete(id) {
    await httpClient.delete(`/team_members/${id}`);
  },
};

<template>
  <div v-if="!loading" class="messaging__wrapper">
    <Conversation
      v-if="currentConversation"
      :conversation="currentConversation"
      :is-header="true"
    />
    <div
      :key="currentConversationId"
      ref="messagesContainer"
      class="messages messaging__messages"
      :class="{ 'messaging__messages--empty': !currentConversation }"
    >
      <infinite-loading direction="top" :identifier="infiniteScrollId" @infinite="infiniteHandler" />
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="message messaging__message-wrapper"
      >
        <div v-if="showLine(message, messages[--index])" class="messaging__message-line-container">
          <div class="messaging__message-line" />
          {{ displayDate(message.createdAt) }}
          <div class="messaging__message-line" />
        </div>
        <div
          class="messaging__message"
          :class="{'messaging__message--reverse': message.memberType !== 'EmployeeProfile' }">
          <div
            v-linkified
            :class="[
              { 'messaging__message-content--employee': message.memberType === 'EmployeeProfile' },
              { 'messaging__message-content': message.memberType !== 'EmployeeProfile' }]">
            {{ message.content }}
          </div>
          <div
            :class="[
              { 'messaging__message-time--employee': message.memberType === 'EmployeeProfile' },
              { 'messaging__message-time': message.memberType !== 'EmployeeProfile' }]">
            {{ displayTime(message.createdAt) }}
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer messaging__sender">
      <div class="messaging__sender-input stacked">
        <textarea
          v-model="message"
          oninput="this.parentNode.dataset.value = this.value"
          rows="1"
          ref="messageTextarea"
          :placeholder="textareaPlaceholder"
          :disabled="isClosed || !currentConversation"
          @keydown.enter.prevent="sendMessage"
        />
      </div>
      <div v-if="sentMessageError" class="massaging__error">
        {{ $t(`messages.errors.${sentMessageError}`) }}
      </div>
      <CButton
        v-if="message && message.length > 0"
        class="messaging__sender-button"
        @click="sendMessage"
      >
        <img src="../../../assets/images/arrow_send.svg" />
      </CButton>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Conversation from './Conversation.vue';
import moment from 'moment';

export default {
  data() {
    return {
      message: '',
      infiniteScrollId: null,
    };
  },
  components: {
    Conversation,
  },
  computed: {
    ...mapState({
      messages: state => state.messages.messages,
      allMessagesLoaded: state => state.messages.allMessagesLoaded,
      conversations: state => state.messages.conversations,
      currentConversationId: state => state.messages.currentConversationId,
      loading: state => state.messages.loading,
      sentMessageError: state => state.messages.sentMessageError,
      conversationsType: state => state.messages.conversationsType,
    }),
    currentConversation() {
      return this.currentConversationId ? _.find(this.conversations, { id: this.currentConversationId }) : null;
    },
    isClosed() {
      return this.conversationsType === 'closed';
    },
    textareaPlaceholder() {
      return this.isClosed ? this.$t('messages.cant_write_message') : this.$t('messages.write_message')
    }
  },
  watch: {
    currentConversationId(value) {
      this.resetErrors();
      this.resetMessages();
      this.setInfiniteScrollId(value);
    },
    message(value) {
      if (this.sentMessageError && !_.isEmpty(value)) {
        this.resetErrors();
      }
    },
  },
  methods: {
    resetMessages() {
      this.$store.commit('messages/setMessages', []);
      this.$store.commit('messages/setNextPage', 1);
      this.$store.commit('messages/setAllMessagesLoaded', false);
    },
    setInfiniteScrollId(newConversationId) {
      this.infiniteScrollId = newConversationId;
    },
    showLine(message, previousMessage) {
      if (_.isNil(previousMessage)) {
        return true;
      }

      let formattedDate = moment(message.createdAt).format('DD-MM-YY');
      let previousFormattedDate = moment(previousMessage.createdAt).format('DD-MM-YY');

      return formattedDate !== previousFormattedDate;
    },
    displayDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    displayTime(date) {
      return moment(date).format('HH:mm');
    },
    async sendMessage() {
      if (_.isEmpty(this.message)) {
        return;
      }

      await this.$store.dispatch('messages/sendMessage', {
        conversationId: this.currentConversationId,
        content: this.message,
      });

      this.message = '';
      // clearing parentNode value to resize input field to initial size after sending message
      const messageTextarea = this.$refs.messageTextarea;
      messageTextarea.parentNode.dataset.value = '';
      this.scrollDown();
    },
    scrollDown() {
      let container = this.$refs.messagesContainer;
      container.scrollTop = container.scrollHeight;
    },
    resetErrors() {
      this.$store.commit('messages/setSentMessageError', null);
    },
    async infiniteHandler($state) {
      if (this.allMessagesLoaded || !this.currentConversationId) {
        $state.complete();
      } else {
        await this.$store.dispatch('messages/getMessages', this.currentConversationId);
        $state.loaded();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .messaging__wrapper {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  .messaging__messages {
    overflow-y: auto;

    &--empty {
      height: calc(100vh - 120px);
    }
  }

  .messaging__message {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 25px;
  }

  .messaging__message-wrapper {
    &:last-child {
      margin-bottom: 10px;
    }
  }

  .messaging__message--reverse {
    flex-direction: row-reverse;
    margin-right: 25px;
  }

  .messaging__message-line-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #C4C4C8;
    font-size: 10px;
    margin: 20px 10px;
  }

  .messaging__message-line {
    border: 1px solid #E8E8EA;
    margin: 0 10px;
    width: 100%;
  }

  .messaging__message-content {
    background: #53629B;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 10px;
    color: #FFFFFF !important;
    margin: 2px 0 3px 5px;
    max-width: 300px;
    padding: 12px 18px;

    ::v-deep a {
      color: #FFFFFF;
    }
  }

  .messaging__message-content--employee {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 10px;
    margin-right: 5px;
    margin-top: 5px;
    max-width: 300px;
    padding: 12px 18px;

    ::v-deep a {
      color: #000000;
    }
  }

  .messaging__message-time {
    color: #B7BDCB;
    font-size: 10px;
    margin-left: 20px;
  }

  .messaging__message-time--employee {
    color: #B7BDCB;
    font-size: 10px;
    margin-right: 20px;
  }

  .messaging__sender {
    border-top: 2px solid #E8E8EA;
    display: flex;
  }

  .messaging__sender-button {
    align-self: flex-end;
    padding: 0.55rem;
  }

  .messaging__sender-input {
    display: inline-grid;
    width: 100%;

    &.stacked {
      padding: .5em;

      &::after,
      input,
      textarea {
        grid-area: 2 / 1;
      }
    }

    &::after,
    input,
    textarea {
      width: 100%;
      max-height: 20vh;
      grid-area: 1 / 2;
      padding: 0.5em;
      margin: 0;
      resize: none;
      background: none;
      appearance: none;
      border: none;
    }

    &::after {
      content: attr(data-value) ' ';
      visibility: hidden;
      white-space: pre-wrap;
    }

    &:focus-within {
      textarea:focus,
      input:focus {
        outline: none;
      }
    }
  }

  .massaging__error {
    align-self: center;
    color: red;
    font-size: 12px;
  }
</style>

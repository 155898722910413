<template>
  <CNavbar light expandable="md">
    <CNavbarNav v-if="items">
      <CNavItem
        v-for="item in items"
        :key="item.key"
        :class="{'active': item.key === selected}"
        @click.native="select(item)"
      >
        {{ $t(`companies.job_offers.index.${item.key}`) }}
        <span v-if="showCounter">({{ item.count }})</span>
      </CNavItem>
    </CNavbarNav>
  </CNavbar>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      showCounter: {
        type: Boolean,
        default: true,
      },
      selected: {
        type: String,
        default: null,
      },
    },
    created() {
      if (!this.selected && this.items[0]) {
        this.select(this.items[0]);
      }
    },
    methods: {
      select(item) {
        this.$emit('selected', item.key);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .underline-navbar.navbar {
    border-bottom: 1px solid #D9D9DF;
    width: 100%;
    padding: 0;

    .active {
      border-bottom: 2px solid #3D405B;
    }
  }

  .border-navbar.navbar {
    border-bottom: 1px solid #70738E;
    width: 100%;
    padding: 0;

    .active {
      border: 1px solid #70738E;
      border-bottom: none;
      color: #C4C5CD;
    }
  }
</style>

<template>
  <CDataTable
    :items="items.items"
    :fields="items.fields"
    :noItemsView="{ noResults: $t('companies.no_results'), noItems: $t('companies.no_items') }"
    class="table-borderless"
    sorter
    hover
    striped
    clickableRows
    @row-clicked="showItem($event)"
  >
    <template #fitnessPercentage="{item}">
      <td>
        {{ item.fitnessPercentage }} %
      </td>
    </template>
    <template #salaryMin="{item}">
      <td>
        {{ item.salaryMin.toLocaleString().replace(',', ' ') }}
      </td>
    </template>
    <template #state="{item}">
      <td>
        {{ item.state.replace('_', ' ') }}
      </td>
    </template>
    <template #showDetails>
      <td>
        <CButton class="table-button">
          <img src="../../../assets/images/arrow_left.svg" />
        </CButton>
      </td>
    </template>
  </CDataTable>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showItem(item) {
      this.$emit('showItem', item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-button {
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }

  ::v-deep .arrow-position {
    position: relative !important;
    left: 2px;
    top: 8px;
  }

  ::v-deep th > div {
    display: inline;
  }
</style>

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from '../../config/locales/en.yml';
import plLocale from '../../config/locales/pl.yml';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "pl",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: { pl: plLocale.pl, en: enLocale.en }
});

export default i18n;

import _ from 'lodash';
import { basicFormStore, basicFormState } from './basic';

const formState = () => ({
  form: {
    email: '',
    password: '',
    rememberMe: false,
  },
  errors: [],
});

const mutations = {
  init(state) {
    _.assign(state, basicFormState(), formState());
  },
};

export default _.defaultsDeep({
  state: basicFormState(),
  mutations,
}, basicFormStore);

import login from './login';
import forgotPassword from './forgot_password';
import resetPassword from './reset_password';
import addRecruiter from './add_recruiter';
import acceptInvitation from './accept_invitation';
import changePassword from './change_password';
import jobOffer from './job_offer';
import hire from './hire';
import notHire from './not_hire';

export default {
  namespaced: true,
  modules: {
    login,
    forgotPassword,
    resetPassword,
    addRecruiter,
    acceptInvitation,
    changePassword,
    jobOffer,
    hire,
    notHire,
  },
};

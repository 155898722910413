import httpClient from '../utils/http_client';

export default {
  async getConversations(selection) {
    const response = await httpClient.get(`/conversations?${selection}=true`);

    return response.data;
  },

  async getMessages(conversationId, params) {
    const response = await httpClient.get(`/conversations/${conversationId}/messages`, { params });

    return response.data;
  },

  async sendMessage(conversationId, params) {
    const response = await httpClient.post(`/conversations/${conversationId}/messages`, params);

    return response.data;
  },
};

import axios from 'axios';
import qs from 'qs';
import { deepHashSnakeCase, deepHashCamelCase } from './keys_converter';

const httpClient = axios.create({
  transformRequest: [(data => (data ? deepHashSnakeCase(data) : undefined)), ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, deepHashCamelCase],
  paramsSerializer: params => qs.stringify(deepHashSnakeCase(params), { arrayFormat: 'brackets', skipNulls: true }),
});

const errorHandler = (error) => {
  if (error.response.status === 401) {
    window.EventBus.$emit('unauthorized', {});
  }

  return Promise.reject(error.response.data);
};

export const mountInterceptor = () => {
  httpClient.interceptors.response.use(response => response, error => errorHandler(error));
};

export default httpClient;

import _ from 'lodash';
import { basicFormAction, basicFormStore, basicFormState } from './basic';
import recruitersRepository from '../../repositories/recruiters_repository';

const formState = () => ({
  form: {
    email: '',
  },
  errors: [],
});

const mutations = {
  init(state) {
    _.assign(state, basicFormState(), formState());
  },
};

const actions = {
  async reset(context, { redirectUrl }) {
    await basicFormAction(context, async () => {
      await recruitersRepository.forgotPassword(_.merge(context.state.form, { redirectUrl }));
    });
  },
};

export default _.defaultsDeep({
  state: basicFormState(),
  mutations,
  actions,
}, basicFormStore);

<template>
  <CDropdown
    v-if="!loading"
    placement="bottom"
    :show.sync="opened"
    :offset="[0, 6]"
  >
    <CButton slot="toggler" class="notification__button">
      <img :src="bellIcon" class="notification__button-img" />
    </CButton>
    <div class="notification__title">
      {{ $t('notifications.notifications') }}
    </div>
    <CDropdownDivider />
    <div class="notification__container" ref="notificationsContainer">
      <div v-for="(item, index) in displayNotifications" :key="index">
        <CDropdownItem>
          <Notification :notification="item" />
        </CDropdownItem>
        <CDropdownDivider />
      </div>
    </div>
    <div class="notification__footer">
      <CButton class="notification__button" @click="showMore">
        {{ $t('notifications.show_more') }}
      </CButton>
    </div>
  </CDropdown>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Notification from './Notification';

export default {
  components: {
    Notification,
  },
  data() {
    return {
      opened: false,
      baseItemsNumber: 6,
      displayedItems: 6,
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications,
      allNotificationsSeen: state => state.notifications.newNotificationsCount === 0,
      loading: state => state.notifications.loading,
    }),
    bellIcon() {
      if (!this.allNotificationsSeen) {
        return require('../../../assets/images/bell_with_dot.svg');
      }

      if (this.opened) {
        return require('../../../assets/images/bell.svg');
      }

      return require('../../../assets/images/bell-color.svg');
    },
    displayNotifications() {
      return _.slice(this.notifications, 0, this.displayedItems);
    },
  },
  created() {
    this.$store.dispatch('notifications/getNewNotificationsCount');
    this.$store.dispatch('notifications/getNotifications');
  },
  watch: {
    opened(value) {
      if (!value) {
        this.displayedItems = this.baseItemsNumber;
      }
      if (value && !this.notificationsSeen) {
        this.markNotificationsAsSeen();
      }
    },
  },
  methods: {
    showMore() {
      if (this.displayedItems > this.notifications.length) {
        return;
      }

      this.displayedItems += this.baseItemsNumber;
      this.$nextTick(function() {
        let container = this.$refs.notificationsContainer;
        container.scrollTop =  this.displayedItems * 30;
      });
    },
    markNotificationsAsSeen() {
      this.$store.dispatch('notifications/markNotificationsAsSeen');
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .dropdown-menu.show {
    display: block;
    border-radius: 10px;
    margin-top: 10px;
  }

  ::v-deep .dropdown-item {
    padding: 0;

    &:active {
      background-color: #ebedef;
      color: #455164;
    }
  }

  ::v-deep .dropdown-divider {
    margin: 0;
  }

  ::v-deep .dropdown {
    button {
      color: #3D405B;
    }
  }

  .notification__button-img {
    width: 20px;
  }

  .notification__title {
    border-radius: 10px 10px 0px 0px;
    display: flex;
    font-weight: 800;
    padding: 5px 45px 15px 45px;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .notification__container {
    max-height: 620px;
    overflow-y: auto;
    width: 340px;
  }

  .notification__footer {
    margin-bottom: -10px;
    text-align: center;
  }

  .notification__button {
    font-size: 12px;
    padding: 10px 0;

    &:focus {
      box-shadow: none;
    }
  }
</style>

<template>
  <div class="custom-textarea__wrapper">
    <CTextarea
      v-model="temporaryContent"
      name="content"
      class="custom-textarea__textarea"
    />
    <div class="custom-textarea__action-buttons">
      <CButton
        color="primary-light"
        variant="ghost"
        @click="cancel()"
      >
        {{ $t('common.cancel') }}
      </CButton>
      <CButton
        color="primary-dark"
        class="custom-textarea__submit-button"
        @click="updateItem()"
      >
        {{ $t('settings.save') }}
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      temporaryContent: null,
    };
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  created() {
    this.temporaryContent = this.content;
  },
  methods: {
    updateItem() {
      this.$emit('update', this.temporaryContent);
    },
    cancel() {
      this.temporaryContent = '';
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-textarea__wrapper {
    width: 100%;
  }

  .custom-textarea__textarea {
    margin: 10px 0;
  }

  .custom-textarea__action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .custom-textarea__submit-button {
    border-radius: 8px;
    margin-left: 5px;
  }
</style>

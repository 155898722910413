<template>
  <CModal
    :centered="true"
    :show.sync="modalVisible"
    :size="size"
    @update:show="closeModal"
  >
    <template slot="body-wrapper">
      <slot name="body-wrapper" />
    </template>
    <template slot="footer">
      <slot name="footer" />
    </template>
  </CModal>
</template>

<script>
export default {
  data() {
    return {
      modalVisible: this.showModal,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
  watch: {
    showModal(value) {
      this.modalVisible = value;
    },
  },
  methods: {
    closeModal() {
      this.$emit('modalClosed');
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .modal-header {
    border: none;
  }

  ::v-deep .modal-content {
    border-radius: 0;
    border: none;
    font-size: 18px;
    text-align: center;

    p {
      font-weight: bold;
    }
  }

  ::v-deep .modal-backdrop {
    background-color: rgba(196, 196, 196, 0.7);
  }

  ::v-deep .modal-footer {
    border: none;
    justify-content: center;
    padding-bottom: 30px;
  }
</style>

<template>
  <div class="header-wrapper">
    <div
      v-if="!showFeedback"
      class="header-top-bar"
      :class="`header-top-bar-${lowerState}`">
      <span
        v-if="recruitmentProcess.employeeFullName"
        class="header-candidate"
      >
        {{ recruitmentProcess.employeeFullName }}
      </span>
      <span
        v-else
        class="header-candidate"
      >
        {{ $t('companies.match.recruitment_process') }}
      </span>
      <div v-if="lowerState === 'ongoing' || lowerState === 'accepted'" class="header-match">
        {{ recruitmentProcess.fitnessPercentage }}%
      </div>
      <div
        v-if="recruitmentProcess.timeLeft"
        class="header-time"
      >
        {{ recruitmentProcess.timeLeft }}
      </div>
      <CButton
        v-if="lowerState === 'ongoing'"
        class="header-accept"
        @click="$emit('accept')"
      >
        {{ $t('common.confirm') }}
      </CButton>
      <CButton
        v-if="lowerState === 'ongoing'"
        class="header-reject"
        @click="$emit('reject')"
      >
        {{ $t('companies.match.reject') }}
      </CButton>
      <CButton
        v-if="lowerState === 'accepted'"
        class="header-hire"
        @click="$emit('hire')"
      >
        {{ $t('companies.match.hire') }}
      </CButton>
      <CButton
        v-if="lowerState === 'accepted'"
        class="header-reject"
        @click="$emit('notHire')"
      >
        {{ $t('companies.match.not_hire') }}
      </CButton>
      <CButton
        v-if="lowerState === 'not_hired'"
        class="header-show-feedback"
        @click="showFeedback = true"
      >
        {{ $t('companies.match.show_feedback') }}
        <img
          src="../../../assets/images/arrow_bottom.svg"
          class="header-feedback-icon" />
      </CButton>
    </div>
    <div v-if="showFeedback" class="header-feedback">
      <div class="row">
        <span class="header-candidate">{{ recruitmentProcess.employeeFullName }}</span>
        <CButton
          class="header-hide-feedback"
          @click="showFeedback = false"
        >
          {{ $t('companies.match.hide_feedback') }}
          <img
            src="../../../assets/images/arrow_top.svg"
            class="header-feedback-icon" />
        </CButton>
      </div>
      <div class="row">
        <div class="col-6">
          <span class="header-feedback-subtitle">{{ $t('companies.match.from_candidate') }}</span>
          <div v-if="recruitmentProcess.employeeFeedback" class="header-feedback-container">
            {{ recruitmentProcess.employeeFeedback.join(', ') }}
          </div>
        </div>
        <div class="col-6">
          <span class="header-feedback-subtitle">{{ $t('companies.match.from_recruiter') }}</span>
          <div v-if="recruitmentProcess.employerFeedback" class="header-feedback-container">
            {{ recruitmentProcess.employerFeedback.join(', ') }}
          </div>
        </div>
      </div>
    </div>
    <CAlert
      :show="showAcceptedAlert"
      closeButton
      class="header-alert"
      @update:show="closeAcceptedAlert">
      <div class="header-title">
        <div class="header-alert-title">Gratulacje!</div>
        <div>{{ $t('companies.match.candidate_has_been_accepted') }}</div>
      </div>
    </CAlert>
  </div>
</template>


<script>
import _ from 'lodash';

export default {
  data () {
    return {
      showFeedback: false,
    }
  },
  props: {
    recruitmentProcess: {
      type: Object,
      required: true,
    },
    showAcceptedAlert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lowerState() {
      return _.toLower(this.recruitmentProcess.state);
    },
  },
  methods: {
    closeAcceptedAlert() {
      this.$emit('closeAcceptedAlert');
    },
  },
}
</script>


<style lang="scss" scoped>
  .header-wrapper {
    width: 100%;
  }

  .header-top-bar {
    color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .header-top-bar-ongoing {
    background: #53629B;
  }

  .header-top-bar-accepted {
    background: #53629B;
  }

  .header-top-bar-hired {
    background: #539B7B;
  }

  .header-top-bar-not_hired {
    background: #F7A123;
  }

  .header-top-bar-rejected {
    background: #F7A123;
  }

  .header-top-bar-expired {
    background: #F7A123;
  }

  .header-top-bar-withdrawn {
    background: #F7A123;
  }

  .header-candidate {
    padding: 20px 30px;
    font-weight: bold;
    font-size: 20px;
  }

  .header-match {
    background: #539B7B;
    border: 1px solid #539B7B;
    box-sizing: border-box;
    margin-left: 30px;
    padding: 27px 5px 0px 5px;
    font-weight: bold;
    font-size: 16px;
    margin-top: -22px;
  }

  .header-time {
    background: #FF3000;
    border: 1px solid #FF3000;
    box-sizing: border-box;
    margin-left: 10px;
    padding: 27px 5px 0px 5px;
    font-weight: bold;
    font-size: 16px;
    margin-top: -22px;
  }

  .header-accept,
  .header-hire {
    background: #FF7252;
    color: white;
    border-radius: 12px;
    margin-left: 70px;
  }

  .header-reject {
    background: #FFFFFF;
    color: #FF7252;
    border: 1px solid #FF7252;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 20px;
  }

  .header-show-feedback {
    color: white;
    margin-left: 55px;

    &:focus {
      box-shadow: none;
    }
  }

  .header-feedback {
    border: 1px solid #F7A123;
    border-radius: 8px;
    color: #F7A123;
  }

  .header-feedback-icon {
    margin-left: 10px;
  }

  .row {
    margin: 0;
    text-align: left;
  }

  .header-hide-feedback {
    color: #F7A123;
    margin-left: 55px;

    &:focus {
      box-shadow: none;
    }
  }

  .header-feedback-subtitle {
    color: black;
    font-weight: bold;
    padding: 20px 15px;
  }

  .header-feedback-container {
    color: black;
    font-style: italic;
    font-size: 12px;
    padding: 10px 15px 40px 15px;
  }

  .header-alert {
    background: #539B7B;
    border-radius: 12px;
    color: white;
    margin-top: 3px;
  }

  .header-title {
    text-align: left;
    padding: 10px;
  }

  .header-alert-title {
    font-weight: bold;
    font-size: 18px;
  }
</style>

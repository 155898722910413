<template>
  <div v-if="!loading">
    <SecondaryTopBar />
    <DetailsModal
      :job-offer="jobOffer"
      :show-modal="showDetails"
      @modalClosed="showDetails = false"
    />

   <ArchiveConfirmationModal
     :job-offer="jobOffer"
     :show-modal="showArchiveConfirmation"
     @archiveConfirmed="archiveOffer"
     @modalClosed="showArchiveConfirmation = false"
   />

    <Header
      :position="jobOffer.position"
      :is-published="jobOffer.state === 'published'"
      @closeOffer="showArchiveConfirmation = true"
      @showDetails="showDetails = true"
    />

    <div class="row">
      <div class="col-4">
        <Summary :jobOffer="jobOffer"/>
        <CustomFields
          :items="jobOffer.notes"
          :title="$t('companies.job_offers.notes')"
          @add="addNote($event)"
          @remove="removeNote($event)"
          class="custom-fields--job-offer-notes"
        />
      </div>
      <div v-if="!processesLoading" class="col-8">
        <NavBar :items="navBarItems" :selected="currentTab" @selected="changeTab" class="underline-navbar" />
        <Table
          :items="currentProcesses"
          @showItem="showRecruitmentProcess($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Header from '../elements/job_offer/Header.vue';
import Summary from '../elements/job_offer/Summary.vue';
import DetailsModal from '../elements/job_offer/DetailsModal.vue';
import ArchiveConfirmationModal from '../elements/job_offer/ArchiveConfirmationModal.vue';
import NavBar from '../elements/custom/NavBar.vue';
import Table from '../elements/job_offer/Table.vue';
import SecondaryTopBar from '../elements/layouts/SecondaryTopBar.vue';
import CustomFields from '../elements/custom/CustomFields.vue';

export default {
  components: {
    Header,
    Summary,
    DetailsModal,
    ArchiveConfirmationModal,
    NavBar,
    Table,
    SecondaryTopBar,
    CustomFields,
  },
  data() {
    return {
      showArchiveConfirmation: false,
      showDetails: false,
      currentTab: null,
      fields: {
        accepted: [
          { key: 'employeeFullName', label: this.$t('companies.job_offer.fields.full_name') },
          { key: 'fitnessPercentage', label: this.$t('companies.job_offer.fields.fitness_score') },
          { key: 'salaryMin', label: this.$t('companies.job_offer.fields.min_salary') },
          { key: 'employerStatus', label: this.$t('companies.job_offer.fields.status')},
          { key: 'recruiterFullName', label: this.$t('companies.job_offer.fields.recruiter') },
          { key: 'showDetails', label: '', _style: 'width:1%', sorter: false, filter: false },
        ],
        finished: [
          { key: 'employeeFullName', label: this.$t('companies.job_offer.fields.full_name') },
          { key: 'fitnessPercentage', label: this.$t('companies.job_offer.fields.fitness_score') },
          { key: 'salaryMin', label: this.$t('companies.job_offer.fields.min_salary') },
          { key: 'state', label: this.$t('companies.job_offer.fields.status') },
          { key: 'recruiterFullName', label: this.$t('companies.job_offer.fields.recruiter') },
          { key: 'showDetails', label: '', _style: 'width:1%', sorter: false, filter: false },
        ],
        ongoing: [
          { key: 'employeeFullName', label: this.$t('companies.job_offer.fields.full_name') },
          { key: 'fitnessPercentage', label: this.$t('companies.job_offer.fields.fitness_score') },
          { key: 'salaryMin', label: this.$t('companies.job_offer.fields.min_salary') },
          { key: 'timeLeft', label: this.$t('companies.job_offer.fields.time_left') },
          { key: 'showDetails', label: '', _style: 'width:1%', sorter: false, filter: false },
        ],
        inactive: [
          { key: 'employeeFullName', label: this.$t('companies.job_offer.fields.full_name') },
          { key: 'fitnessPercentage', label: this.$t('companies.job_offer.fields.fitness_score') },
          { key: 'salaryMin', label: this.$t('companies.job_offer.fields.min_salary') },
          { key: 'state', label: this.$t('companies.job_offer.fields.status') },
          { key: 'showDetails', label: '', _style: 'width:1%', sorter: false, filter: false },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      jobOffer: state => state.jobOffers.jobOffer,
      loading: state => state.jobOffers.loading,
      notice: state => state.jobOffers.notice,
      processes: state => state.recruitmentProcesses.recruitmentProcesses,
      processesLoading: state => state.recruitmentProcesses.loading,
    }),
    processesTypes() {
      return _.keys(this.processes);
    },
    navBarItems() {
      return _.map(this.processesTypes, (type) => (
        { key: type, count: _.size(this.processes[type]) }
      ));
    },
    currentProcesses() {
      return { items: this.processes[this.currentTab], fields: this.fields[this.currentTab] };
    },
  },
  async created() {
    await this.getJobOffer();
    await this.getRecruitmentProcesses();
    this.setInitialTab();
  },
  methods: {
    async getJobOffer() {
      await this.$store.dispatch('jobOffers/show', this.$route.params.id);
    },
    async getRecruitmentProcesses() {
      await this.$store.dispatch('recruitmentProcesses/index', this.$route.params.id);
    },
    async archiveOffer() {
      let notice = await this.$store.dispatch('jobOffers/archive', this.jobOffer.id);
      this.displayNotice(notice);
      this.showArchiveConfirmation = false
    },
    changeTab(selected) {
      this.currentTab = selected;
    },
    setInitialTab() {
      this.changeTab(this.processesTypes[0]);
    },
    showRecruitmentProcess(recruitmentProcessId) {
      this.$router.push({ name: 'recruitment_process', params: { id: recruitmentProcessId } });
    },
    displayNotice(value) {
      if (value.length > 0) {
        this.$store.dispatch('toaster/display', {
          messages: [{ message: value }],
          autoHide: 5000,
        });
      }
    },
    async addNote(value) {
      await this.$store.dispatch('jobOffers/addNote', { id: this.jobOffer.id, content: value });
    },
    async removeNote(value) {
      await this.$store.dispatch('jobOffers/removeNote', { id: this.jobOffer.id, noteId: value.item.id });
    },
  },
};
</script>

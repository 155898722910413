import httpClient from '../utils/http_client';

export default {
  async create(recruitmentProcessId, params) {
    const response = await httpClient.post(`/recruitment_processes/${recruitmentProcessId}/notes`, params);

    return response.data;
  },

  async destroy(recruitmentProcessId, noteId) {
    const response = await httpClient.delete(`/recruitment_processes/${recruitmentProcessId}/notes/${noteId}`);

    return response.data;
  },
};

<template>
  <CustomModal :showModal="showModal" size="lg" @modalClosed="closeModal">
    <template slot="body-wrapper">
      <div class="not-hire-modal-body">
        <span class="not-hire-modal-span">{{ $t('companies.match.fill_the_opinion') }}</span>
        <div class="not-hire-modal-content">
          {{ $t('companies.match.what_went_wrong') }}
          <CTextarea
            v-model="feedback"
            class="not-hire-modal-textarea" />
        </div>
      </div>
    </template>
    <template slot="footer">
      <CButton
        :disabled="!feedback"
        color="primary-dark"
        shape="pill"
        @click="cancelMatch"
      >
        {{ $t('common.confirm') }}
      </CButton>
      <CButton
        shape="pill"
        class="not-hire-modal-confirm"
        @click="closeModal"
      >
        {{ $t('common.cancel') }}
      </CButton>
    </template>
  </CustomModal>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import CustomModal from '../custom/CustomModal.vue';

const { mapFields } = createHelpers({
  getterType: 'forms/notHire/getField',
  mutationType: 'forms/notHire/updateField',
});

export default {
  components: {
    CustomModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapFields([
      'form.feedback',
    ]),
    ...mapState({
      recruitmentProcess: state => state.jobOffers.recruitmentProcess,
      loading: state => state.forms.notHire.loading,
      success: state => state.forms.notHire.success,
    }),
  },
  created() {
    this.$store.commit('forms/notHire/init');
  },
  methods: {
    closeModal() {
      this.$emit('modalClosed');
    },
    async cancelMatch() {
      if (this.loading) {
        return;
      }

      await this.$store.dispatch('forms/notHire/notHire', {
        id: this.$route.params.id
      });
      if (this.success) {
        this.closeModal();
        await this.$store.dispatch('recruitmentProcesses/show', {
          id: this.$route.params.id
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .not-hire-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 20px 0;
  }

  .not-hire-modal-span {
    font-size: 18px;
  }

  .not-hire-modal-content {
    margin-top: 30px;
  }

  ::v-deep .modal-content {
    font-size: 14px;
    text-align: left;
  }

  ::v-deep textarea.form-control {
    min-height: 150px;
  }

  ::v-deep .modal-footer {
    margin-bottom: 30px;
  }

  .not-hire-modal-textarea {
    margin-top: 5px;
    width: 285px;
  }

  .not-hire-modal-confirm {
    border: 1px solid #F7A123;
    color: #F7A123;
    margin-left: 8px;
  }
</style>

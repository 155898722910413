import messagesRepository from '../repositories/messages_repository';
import _ from 'lodash';

const getters = {};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setConversations(state, conversations) {
    state.conversations = conversations;
  },
  setCurrentConversationId(state, id) {
    state.currentConversationId = id;
  },
  resetCurrentConversationUnread(state, id) {
    if (_.find(state.conversations, { id }).unread) {
      _.find(state.conversations, { id }).unread = false;
    }
  },
  setConversationsType(state, type) {
    state.conversationsType = type;
  },
  setMessages(state, messages) {
    state.messages = _.reverse(messages);
  },
  concatMessages(state, messages) {
    const prevMessages = state.messages;
    state.messages = _.concat(_.reverse(messages), prevMessages);
  },
  addMessage(state, message) {
    state.messages.push(message);
  },
  setAllMessagesLoaded(state, allMessagesLoaded) {
    state.allMessagesLoaded = allMessagesLoaded;
  },
  setNextPage(state, nextPage) {
    state.nextPage = nextPage;
  },
  setSentMessageError(state, error) {
    state.sentMessageError = error;
  },
};

const actions = {
  async getConversations(context, selection) {
    context.commit('setLoading', true);

    const conversations = await messagesRepository.getConversations(selection);
    context.commit('setConversations', conversations);

    context.commit('setLoading', false);
  },
  async getMessages(context, conversationId) {
    let params = { page: context.state.nextPage };
    const messages = await messagesRepository.getMessages(conversationId, params);

    if (_.size(messages) === 0) {
      context.commit('setAllMessagesLoaded', true);
    } else {
      context.commit('concatMessages', messages);
      context.commit('setNextPage', context.state.nextPage + 1);
    }
  },
  async sendMessage(context, { conversationId, content }) {
    let response = await messagesRepository.sendMessage(conversationId, { content });
    if (response.failure) {
      context.commit('setSentMessageError', response.failure.conversation)
    } else {
      context.commit('addMessage', response.message);
    }
  },
};

export default {
  namespaced: true,
  state: {
    loading: true,
    conversations: [],
    currentConversationId: null,
    conversationsType: 'yours',
    messages: [],
    nextPage: 1,
    allMessagesLoaded: false,
    sentMessageError: null,
  },
  getters,
  mutations,
  actions,
};

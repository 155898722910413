<template>
  <div v-if="show">
    <CToaster :autohide="autoHide">
      <template v-for="item in messages">
        <CToast
          :key="fixedToasts"
          :show="true"
          :header="item.header"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      messages: state => state.toaster.messages,
      autoHide: state => state.toaster.autoHide,
      show: state => state.toaster.show,
      fixedToasts: state => state.toaster.fixedToasts,
    }),
  }
}
</script>

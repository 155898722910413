<template>
  <div class="team-management-wrapper">
    <div class="team-management-header">
      {{ $t(`settings.team`)}}
    </div>
    <div class="team-management-subheader">
      {{ $t(`settings.team_description`)}}
    </div>
    <TeamTable
      v-if="teamList.length > 0"
      :items="items"
      :show-header="false"
      :show-filter="false"
      :border="false"
      :striped="false"
      :clickableRows="false"
      @recruiterDeleted="getTeam"
    />
    <AddRecruiterForm @recruiterInvited="getTeam" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import AddRecruiterForm from '../auth/AddRecruiterForm.vue'
import TeamTable from './TeamTable.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'fullName' },
        { key: 'email' },
        { key: 'role' },
        { key: 'confirmedAt' },
        { key: 'icon' },
      ],
    };
  },
  components: {
    AddRecruiterForm,
    TeamTable,
  },
  computed: {
    ...mapState({
      teamList: state => state.teamMembers.list,
    }),
    items() {
      return { items: this.teamList, fields: this.fields };
    },
  },
  async created() {
    if (_.isEmpty(this.teamList)) {
      await this.getTeam();
    }
  },
  methods: {
    async getTeam() {
      await this.$store.dispatch('teamMembers/index');
    },
  }
};
</script>

<style lang="scss" scoped>
  .team-management-wrapper {
    margin-top: 70px;
  }

  .team-management-header {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .team-management-subheader {
    margin-bottom: 30px;
  }
</style>

<template>
  <div class="match-skill-wrapper" :class="borderStyle">
    <div :class="nameStyle">
      <span class="match-skill-name">{{ skill.name }}</span>
      <span v-if="skill.significant">
        <img v-if="skillInExperience" class="match-skill-img" src="../../../assets/images/diamond-white.svg" />
        <img v-else class="match-skill-img" src="../../../assets/images/diamond-disabled.svg" />
      </span>
    </div>
    <div v-if="considerMonths && !experienceId && months > 0" class="match-skill-months">
      {{ displayMonths }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    skill: {
      type: Object,
      required: true,
    },
    experienceId: {
      type: Number,
      default: null,
    },
    considerMonths: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    borderStyle() {
      if (this.skillInExperience) {
        if (this.months > 0) {
          return 'match-skill-border-months-checked';
        } else {
          return 'match-skill-border-checked';
        }
      } else {
        return 'match-skill-border-unchecked';
      }
    },
    skillInExperience() {
      return !(this.experienceId && !this.hasExperience);
    },
    nameStyle() {
      if (this.skillInExperience) {
        return 'match-skill-name-checked';
      } else {
        return 'match-skill-name-unchecked';
      }
    },
    displayMonths() {
      if (this.months >= 12) {
        const years = _.toInteger(this.months / 12);
        return this.$tc('companies.match.years_more_than_required', years, { years });
      } else {
        return this.$tc('companies.match.months_more_than_required', this.months, { months: this.months });
      }
    },
    months() {
      if (this.considerMonths && !_.isNil(this.skill.months) && !_.isNil(this.skill.minMonths) && this.skill.minMonths > 0) {
        return this.skill.months - this.skill.minMonths;
      } else {
        return 0;
      }
    },
    hasExperience() {
      // When selecting job experience highlight skills with experience and any general and tier skills
      return _.includes(this.skill.jobExperiencesIds, this.experienceId) || this.skill.proficiencyType == "general" || this.skill.proficiencyType == "tier";
    },
  },
}
</script>

<style lang="scss" scoped>
  .match-skill-wrapper {
    border: 1px solid;
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
  }

  .match-skill-img {
    height: 14px;
    width: 14px;
    margin-left: 3px;
  }

  .match-skill-border-unchecked {
    border-color: #C4C4C4;
    height: 38px;
  }

  .match-skill-border-months-checked {
    border-color: #70738E;
  }

  .match-skill-border-checked {
    border-color: #70738E;
    height: 38px;
  }

  .match-skill-name-unchecked {
    background: transparent;
    border-color: #C4C4C4;
    color: #C4C4C4;
    padding: 8px 13px;
  }

  .match-skill-name-checked {
    background: #70738E;
    border-color: #70738E;
    color: white;
    padding: 8px 13px;
  }

  .match-skill-months {
    background: transparent;
    border-color: #70738E;
    color: #70738E;
    padding: 2px 15px;
  }
</style>

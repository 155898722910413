import notificationsRepository from '../repositories/notifications_repository';

const getters = {};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  setNewNotificationsCount(state, newNotificationsCount) {
    state.newNotificationsCount = newNotificationsCount;
  },
  setUnreadConversations(state, unreadConversations) {
    state.unreadConversations = unreadConversations;
  },
};

const actions = {
  async getNotifications(context) {
    context.commit('setLoading', true);

    const notifications = await notificationsRepository.index();
    context.commit('setNotifications', notifications);

    context.commit('setLoading', false);
  },
  async getNewNotificationsCount(context) {
    context.commit('setLoading', true);

    const newNotificationsCount = await notificationsRepository.getNewNotificationsCount();
    context.commit('setNewNotificationsCount', newNotificationsCount);

    context.commit('setLoading', false);
  },
  async markNotificationsAsSeen(context) {
    const newNotificationsCount = await notificationsRepository.markNotificationsAsSeen();
    context.commit('setNewNotificationsCount', newNotificationsCount);
  },
  async getConversationsStatus(context) {
    const status = await notificationsRepository.getConversationsStatus();
    context.commit('setUnreadConversations', status.unreadConversations);
  },
};

export default {
  namespaced: true,
  state: {
    loading: true,
    notifications: [],
    newNotificationsCount: 0,
    unreadConversations: false,
  },
  getters,
  mutations,
  actions,
};

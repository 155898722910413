<template>
  <div class="recent-feedbacks__wrapper">
    <div class="recent-feedbacks__title">
      {{ $t('dashboard.recent_recruitment_feedback') }}
    </div>
    <div v-if="!items || items.length === 0" class="recent-feedbacks__comment">
      {{ $t('dashboard.no_feedback_yet') }}
    </div>
    <RecentFeedback
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      class="recent-feedbacks__item"
      @click.native="goToRecruitemtProcess(item.id)"
    />
  </div>
</template>

<script>
import RecentFeedback from './RecentFeedback.vue';

export default {
  components: {
    RecentFeedback,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToRecruitemtProcess(id) {
      this.$router.push({ name: 'recruitment_process', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
  .recent-feedbacks__wrapper {
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    min-height: 458px;
    padding: 20px 25px;
  }

  .recent-feedbacks__title {
    color: #3D405B;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .recent-feedbacks__comment {
    font-style: italic;
  }

  .recent-feedbacks__item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &:active {
      transform: translateY(2px);
    }
  }
</style>

import _ from 'lodash';
import { basicFormAction, basicFormStore, basicFormState } from './basic';
import jobOffersRepository from '../../repositories/job_offers_repository';

const formState = () => ({
  form: {
    position: '',
    locationIds: [],
    industryId: '',
    specializationId: '',
    recruiterId: '',
    employmentTypes: [],
    benefitIds: [],
    requiredSkills: [],
    desirableSkills: [],
    requirements: [],
    details: [],
  },
});

const mutations = {
  init(state) {
    _.assign(state, basicFormState(), formState());
  },
  assignJobOfferAttributes(state, jobOfferAttributes) {
    _.assign(state.form, {
      position: jobOfferAttributes.position,
      locationIds: jobOfferAttributes.locationIds,
      industryId: jobOfferAttributes.industry ? jobOfferAttributes.industry.id : null,
      specializationId: jobOfferAttributes.specialization ? jobOfferAttributes.specialization.id : null,
      recruiterId: jobOfferAttributes.recruiter ? jobOfferAttributes.recruiter.id : null,
      employmentTypes: jobOfferAttributes.employmentTypes,
      benefitIds: _.map(jobOfferAttributes.benefits, 'id'),
      requiredSkills: jobOfferAttributes.requiredSkills,
      desirableSkills: jobOfferAttributes.desirableSkills,
      requirements: jobOfferAttributes.requirements,
      details: jobOfferAttributes.details,
    });
  }
};

const actions = {
  async fetchOfferOptions(context) {
    context.commit('setLoading', true);
    const offerOptions = await jobOffersRepository.offerOptions();

    context.commit('setOfferOptions', offerOptions);
    context.commit('setLoading', false);
  },
  async create(context, { jobOfferDraftId, publish }) {
    await basicFormAction(context, async () => {
      await jobOffersRepository.create(_.merge({ jobOfferDraftId }, context.state.form, ({ publish })));
    });
  },
  async fetchJobOfferForEdition(context, id) {
    context.commit('setLoading', true);

    const jobOfferAttributes = await jobOffersRepository.edit(id);
    context.commit('assignJobOfferAttributes', jobOfferAttributes);

    context.commit('setLoading', false);
  },
  async deleteDraft(context, { draftId }) {
    context.commit('setLoading', true);
    await jobOffersRepository.deleteDraft(draftId);
    context.commit('setLoading', false);
  }
};

export default _.defaultsDeep({
  state: basicFormState(),
  mutations,
  actions,
}, basicFormStore);

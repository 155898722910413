<template>
  <div class="messages__wrapper">
    <Conversations class="messages__conversations" />
    <Messaging class="messages__messaging" />
    <Details class="messages__details" />
  </div>
</template>

<script>
  import Conversations from '../elements/messages/Conversations.vue'
  import Messaging from '../elements/messages/Messaging.vue'
  import Details from '../elements/messages/Details.vue'

  export default {
    components: {
      Conversations,
      Messaging,
      Details,
    },
  };
</script>

<style lang="scss" scoped>
  .messages__wrapper {
    background-color: #fff;
    display: flex;
    height: calc(100vh - 66px);
    margin: -80px -150px;
  }

  .messages__conversations,
  .messages__details {
    flex: 2;
  }

  .messages__messaging {
    flex: 3;
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTable',{staticClass:"table-borderless",attrs:{"items":_vm.items.items,"fields":_vm.items.fields,"header":false,"hover":""},scopedSlots:_vm._u([{key:"fullName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("\n      "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+"\n    ")])]}},{key:"role",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("\n      "+_vm._s(_vm.lowerCaseRole(item.role))+"\n    ")])]}},{key:"confirmedAt",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"team-table-invitation-sent"},[_vm._v("\n      "+_vm._s(item.confirmedAt ? '' : _vm.$t('settings.invitation_has_been_sent'))+"\n    ")])]}},{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"delete-icon",on:{"click":function($event){return _vm.showDeletionModal(item.id)}}},[_vm._v("\n        x\n      ")]),_vm._v(" "),_c('CustomModal',{attrs:{"show-modal":_vm.deleteRecruiterId === item.id},on:{"modalClosed":function($event){_vm.deleteRecruiterId = null}}},[_c('template',{slot:"body-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t('settings.delete_message')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName)+", "+_vm._s(_vm.lowerCaseRole(item.role))+"?")])]),_vm._v(" "),_c('template',{slot:"footer"},[_c('CButton',{attrs:{"color":"primary-dark","shape":"pill"},on:{"click":_vm.cancel}},[_vm._v("\n            "+_vm._s(_vm.$t('common.cancel'))+"\n          ")]),_vm._v(" "),_c('CButton',{staticClass:"team-table-confirm",attrs:{"shape":"pill"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_vm._v("\n            "+_vm._s(_vm.$t('common.confirm'))+"\n          ")])],1)],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
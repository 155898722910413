<template>
  <form class="accept-invitation-form-wrapper" @submit.prevent="submit">
    <CustomInput
      v-model="password"
      :label="$t('accept_invitation.password.label')"
      :required="true"
      type="password"
      class="underline-input accept-invitation-form-input"
    />

    <CustomInput
      v-model="passwordConfirmation"
      name="passwordConfirmation"
      :label="$t('accept_invitation.password_confirmation.label')"
      :required="true"
      type="password"
      class="underline-input accept-invitation-form-input"
    />

    <CButton
      type="submit"
      color="primary-dark"
      shape="pill"
      :disabled="loading"
      class="text-white accept-invitation-form-button"
    >
      {{ $t('accept_invitation.accept') }}
    </CButton>
  </form>
</template>

<script>
import _ from 'lodash';
import { Base64 } from 'js-base64';
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import CustomInput from '../../elements/custom/CustomInput.vue';

const { mapFields } = createHelpers({
  getterType: 'forms/acceptInvitation/getField',
  mutationType: 'forms/acceptInvitation/updateField',
});

export default {
  components: {
    CustomInput,
  },
  computed: {
    ...mapFields([
      'form.password',
      'form.passwordConfirmation',
    ]),
    ...mapState({
      errors: state => state.forms.acceptInvitation.errors,
      success: state => state.forms.acceptInvitation.success,
      loading: state => state.forms.acceptInvitation.loading,
    }),
  },
  watch: {
    errors(value) {
      if (_.isNil(value)) {
        return;
      }

      let params = {};
      if (_.isString(value)) {
        params = { messages: [{ message: value }], autoHide: 5000 };
      } else {
        const messages = _.map(value, (item, key) => ({
          message: item.join(', '),
          header: this.$t(`accept_invitation.${_.snakeCase(key)}.label`),
        }));
        params = { messages: messages, autoHide: 5000 };
      }
      this.$store.dispatch('toaster/display', params);
    },
  },
  created() {
    this.$store.commit('forms/acceptInvitation/init');
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      await this.$store.dispatch('forms/acceptInvitation/acceptInvitation', this.$route.params.token);
      if (this.success) {
        const email = Base64.decode(this.$route.params.email);

        await this.$auth.login({
          data: {
            email: email,
            password: this.password,
          },
          rememberMe: true,
          redirect: {
            name: 'dashboard',
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .accept-invitation-form-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .accept-invitation-form-input {
    margin-top: 30px;
  }

  .accept-invitation-form-button {
    font-size: 18px;
    margin-top: 30px;
    padding: 8px 30px;
  }
</style>

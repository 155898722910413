import httpClient from '../utils/http_client';

export default {
  async create(jobOfferId, params) {
    const response = await httpClient.post(`/job_offers/${jobOfferId}/notes`, params);

    return response.data;
  },

  async destroy(jobOfferId, noteId) {
    const response = await httpClient.delete(`/job_offers/${jobOfferId}/notes/${noteId}`);

    return response.data;
  },
};

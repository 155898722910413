import _ from 'lodash';
import { basicFormAction, basicFormStore, basicFormState } from './basic';
import recruitersRepository from '../../repositories/recruiters_repository';

const formState = () => ({
  form: {
    password: '',
    passwordConfirmation: '',
  },
  resetPasswordToken: null,
});

const mutations = {
  init(state, resetPasswordToken) {
    _.assign(state, basicFormState(), formState());
    state.resetPasswordToken = resetPasswordToken;
  },
};

const actions = {
  async resetPassword(context) {
    await basicFormAction(context, async () => {
      await recruitersRepository.changePassword({
        ...context.state.form,
        resetPasswordToken: context.state.resetPasswordToken,
      });
    });
  },
};

export default _.defaultsDeep({
  state: basicFormState(),
  actions,
  mutations,
}, basicFormStore);

<script>
import _ from 'lodash';
import moment from 'moment';
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    x: {
      type: Array,
      default: null,
    },
    y: {
      type: Array,
      required: true,
    },
  },
  computed: {
    chartdata() {
      return {
        labels: this.x,
        datasets: [{
          backgroundColor: '#FAC984',
          data: this.y,
          borderColor: '#FAC984',
          pointBorderColor: '#FAC984',
        }]
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title(tooltipItem, data) {
              return `${moment(tooltipItem[0].label).format('DD.MM.YYYY')}`;
            },
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#C4C4C4',
              fontSize: 14,
              maxTicksLimit: 4,
            },
            gridLines: {
              display: true,
              drawBorder: false,
              drawTicks: false,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
              drawBorder: true,
              drawTicks: false,
            },
            ticks: {
              fontSize: 12,
              fontColor: '#70738E',
              callback(value) {
                return moment(value).format('DD');
              },
            },
          }],
        },
      };
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<template>
  <div v-if="!loading" class="conversations__wrapper">
    <CustomInput
      v-model="search"
      :placeholder="$t('messages.search_placeholder')"
      name="search"
      class="conversations__search search-input"
    />
    <div class="conversations__title">
      {{ $t('messages.messages') }}
    </div>
    <CDropdown
      nav
      :togglerText="$t(`messages.selections.${conversationsType}`)"
      placement="bottom-end"
      class="navbar-dropdown"
    >
      <CDropdownItem v-for="item in selections" :key="item" @click.native="setConversationsType(item)">
        {{ $t(`messages.selections.${item}`) }}
      </CDropdownItem>
    </CDropdown>
    <div class="conversations__items">
      <Conversation
        v-for="conversation in sortedConversations"
        :key="conversation.id"
        :conversation="conversation"
        :is-opened="currentConversationId === conversation.id"
        @click.native="setConversation(conversation.id)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CustomInput from '../custom/CustomInput.vue';
import Conversation from './Conversation.vue';

export default {
  components: {
    CustomInput,
    Conversation,
  },
  data() {
    return {
      search: null,
      selections: ['yours', 'all', 'closed'],
    };
  },
  computed: {
    ...mapState({
      conversations: state => state.messages.conversations,
      currentConversationId: state => state.messages.currentConversationId,
      loading: state => state.messages.loading,
      conversationsType: state => state.messages.conversationsType,
      unreadConversations: state => state.notifications.unreadConversations,
    }),
    filteredConversations() {
      return _.filter(this.conversations, (conversation) => {
        const search = _.toLower(this.search);
        return (_.toLower(conversation.employeeFullName).includes(search) || _.toLower(conversation.position).includes(search));
      });
    },
    sortedConversations() {
      return _.orderBy(this.filteredConversations, 'lastMessageSentAt', 'desc');
    },
  },
  async created() {
    await this.getConversations();
    this.setInitialConversation();
  },
  destroyed() {
    this.setCurrentConversationId(null);
  },
  watch: {
    async conversationsType() {
      this.setCurrentConversationId(null);
      await this.getConversations();
      this.setInitialConversation();
    },
  },
  methods: {
    setConversation(conversationId) {
      this.setCurrentConversationId(conversationId);
      this.resetCurrentConversationUnread(conversationId);
      this.refreshConversationsStatus();
    },
    async getConversations() {
      await this.$store.dispatch('messages/getConversations', this.conversationsType);
    },
    setInitialConversation() {
      if (this.conversations && this.conversations.length > 0) {
        if (this.$route.params.conversationId) {
          this.setConversation(this.$route.params.conversationId);
        } else {
          this.setConversation(this.sortedConversations[0].id);
        }
      }
    },
    setCurrentConversationId(conversationId) {
      this.$store.commit('messages/setCurrentConversationId', conversationId);
    },
    resetCurrentConversationUnread(conversationId) {
      this.$store.commit('messages/resetCurrentConversationUnread', conversationId);
    },
    setConversationsType(type) {
      this.$store.commit('messages/setConversationsType', type);
    },
    refreshConversationsStatus() {
      if (this.unreadConversations) {
        this.$store.dispatch('notifications/getConversationsStatus');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .conversations__wrapper {
    border-right: 1px solid #ECEDF4;
    padding: 30px 50px;
  }

  .conversations__title {
    font-size: 18px;
    font-weight: 700;
    margin-top: 30px;
  }

  .conversations__items {
    max-height: calc(100vh - 280px);
    margin: 30px -50px 0 -50px;
    padding: 0 50px;
    overflow-y: auto;
  }

  .navbar-dropdown {
    ::v-deep .btn {
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }

    ::v-deep .dropdown-item {
      font-size: 12px;

      &:active,
      &:hover {
        background-color: #FAFAFE;
        color: #3D405B;
      }

      &:active {
        font-weight: 700;
      }
    }
  }
</style>

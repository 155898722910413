<template>
  <div class="custom-field__item-wrapper">
    <CustomTextArea
      v-if="showEditDialog"
      :content="content"
      @cancel="showEditDialog = false"
      @update="editItem($event)"
    />
    <div v-else class="custom-field__item">
      <div v-if="item.recruiterName" class="custom-field__recruiter">
        {{ item.recruiterName }}
      </div>
        <div v-if="item.createdAt">
        {{ item.createdAt }}
      </div>
      <div v-linkified class="custom-field__text" v-html="item.content" />
    </div>
    <div v-if="!showEditDialog" class="custom-field__buttons-wrapper">
      <CButton
        v-if="allowEdit"
        @click="showEdit(item, index)"
        class="custom-field__edit-button"
      >
        {{ $t('companies.job_offers.job_offer_form.edit') }}
      </CButton>
      <CButton
        @click="removeItem(item, index)"
        class="custom-field__remove-button"
      >
        {{ $t('companies.job_offers.job_offer_form.remove') }}
      </CButton>
    </div>
  </div>
</template>

<script>
import CustomTextArea from './CustomTextArea.vue';

export default {
  data() {
    return {
      showEditDialog: false,
      content: '',
    };
  },
  components: {
    CustomTextArea,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    editItem(updatedContent) {
      const index = this.index;
      this.$emit('edit', { updatedContent, index });
      this.showEditDialog = false;
    },
    removeItem(item, index) {
      this.$emit('remove', { item, index });
    },
    showEdit(item, index) {
      this.index = index;
      this.showEditDialog = true;
      this.content = item.content;
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-field__item-wrapper {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }

  .custom-field__item {
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    padding: 5px 15px;
    width: 100%;
    overflow-wrap: anywhere;
  }

  .custom-field__buttons-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .custom-field__edit-button,
  .custom-field__remove-button {
    font-size: 12px;
    text-decoration-line: underline;
    color: #C4C4C4;
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .custom-field__text {
    ::v-deep a.linkified {
      color: #70738E;
    }
  }

// custom classes achieved by calling e.g. <CustomFields class="custom-fields--job-offer-notes" />
  .custom-fields--job-offer-notes,
  .custom-fields--recruitment-process-notes,
  .custom-fields--recruitment-process-status {
    .custom-field__buttons-wrapper {
      margin-left: 0;
    }

    .custom-field__remove-button {
      display: none;
    }

    .custom-field__item-wrapper {
      &:hover {
        .custom-field__remove-button {
          display: block;
          margin-left: -60px;
          padding: 10px 15px;
        }
      }
    }

    .custom-field__item {
      border-radius: 0;
      padding: 20px;
    }

    .custom-field__recruiter {
      font-weight: bold;
    }

    .custom-field__text {
      font-style: italic;
      padding-top: 15px;
    }
  }

  .custom-fields--recruitment-process-status {
    .custom-field__text {
      padding-top: 0;
    }
  }
</style>

import httpClient from '../utils/http_client';

export default {
  async index() {
    const response = await httpClient.get('/job_offers');

    return response.data;
  },

  async show(id) {
    const response = await httpClient.get(`/job_offers/${id}`);

    return response.data;
  },

  async edit(id) {
    const response = await httpClient.get(`/job_offers/${id}/edit`);

    return response.data;
  },

  async create(params) {
    const response = await httpClient.post('/job_offers', params);

    return response.data;
  },

  async update({ id, recruiterId }) {
    const response = await httpClient.put(`/job_offers/${id}`, { recruiterId });

    return response.data;
  },

  async deleteDraft(draftId) {
    const response = await httpClient.delete(`/job_offer_drafts/${draftId}`);

    return response.data;
  },

  async archive(id) {
    const response = await httpClient.put(`/job_offers/${id}/archive`);

    return response.data;
  },

  async offerOptions() {
    const response = await httpClient.get('/job_offers/offer_options');

    return response.data;
  },
};

<template>
  <form class="row add-recruiter-form-wrapper" @submit.prevent="submit">

    <CustomInput
      v-model="firstName"
      :label="$t('settings.first_name.label')"
      :placeholder="$t('settings.first_name.placeholder')"
      :errors="errors.firstName"
      class="border-input add-recruiter-input"
    />

    <CustomInput
      v-model="lastName"
      :label="$t('settings.last_name.label')"
      :placeholder="$t('settings.last_name.placeholder')"
      :errors="errors.lastName"
      class="border-input add-recruiter-input"
    />

    <CustomInput
      v-model="email"
      :label="$t('settings.email.label')"
      :placeholder="$t('settings.email.placeholder')"
      :errors="errors.email"
      class="border-input add-recruiter-input"
    />

    <CSelect
      :value.sync="role"
      :label="$t('settings.role.label')"
      :options=roleOptions
      class="add-recruiter-select"
    />

    <CButton
      type="submit"
      color="primary-dark"
      shape="pill"
      :disabled="loading"
      class="text-white add-recruiter-button"
    >
      {{ $t('settings.add') }}
    </CButton>
  </form>
</template>

<script>
import CustomInput from '../custom/CustomInput.vue';
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'forms/addRecruiter/getField',
  mutationType: 'forms/addRecruiter/updateField',
});
export default {
  components: {
    CustomInput,
  },
  computed: {
    ...mapFields([
      'form.firstName',
      'form.lastName',
      'form.email',
      'form.role',
    ]),
    ...mapState({
      errors: state => state.forms.addRecruiter.errors,
      loading: state => state.forms.addRecruiter.loading,
      success: state => state.forms.addRecruiter.success,
    }),
    roleOptions() {
      return [
        { label: this.$t('settings.role.manager'), value: 'manager' },
        { label: this.$t('settings.role.recruiter'), value: 'recruiter' }
      ];
    },
  },
  created() {
    this.$store.commit('forms/addRecruiter/init');
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }
      await this.$store.dispatch('forms/addRecruiter/invite');
      this.$emit('recruiterInvited');
    },
  },
};
</script>

<style lang="scss" scoped>
  .add-recruiter-form-wrapper {
    padding: 0 15px;
    margin-top: 20px;
  }

  .add-recruiter-input,
  .add-recruiter-select {
    margin-right: 10px;
  }

  .form-group {
    width: 150px;

    ::v-deep select.form-control {
      border: 1px solid #E5E5E5;
      border-radius: 10px;
    }

    ::v-deep label {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .add-recruiter-button {
    height: 34px;
    width: 100px;
    margin-top: 25px;
    margin-left: 20px;
    padding: 0 25px;
  }
</style>

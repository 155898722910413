import Vue from 'vue';
import App from './components/App.vue'
import moment from 'moment';
import 'moment-timezone';
import CoreuiVue from '@coreui/vue';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth';
import VueAuthDevise from '@websanova/vue-auth/drivers/auth/devise';
import VueAuthAxios from '@websanova/vue-auth/drivers/http/axios.1.x';
import VueAuthRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x';
import { sync } from 'vuex-router-sync';
import linkify from 'vue-linkify';
import InfiniteLoading from 'vue-infinite-loading';

import store from './store';
import router from './router';
import i18n from './i18n';

import httpClient, { mountInterceptor } from './utils/http_client';

Vue.router = router;
Vue.directive('linkified', linkify);
Vue.use(CoreuiVue);
Vue.use(VueAxios, httpClient);
Vue.axios.defaults.baseURL = '/companies/api/';

Vue.use(VueAuth, {
  auth: VueAuthDevise,
  http: VueAuthAxios,
  router: VueAuthRouter,
  loginData: { url: 'auth/sign_in' },
  refreshData: { enabled: false },
  logoutData: { url: 'auth/sign_out', makeRequest: true, method: 'DELETE', redirect: false },
  registerData: { url: 'auth', method: 'POST', redirect: false },
  fetchData: { url: 'recruiter' },
  notFoundRedirect: { name: 'dashboard' },
});
Vue.use(InfiniteLoading, {
  slots: {
    noResults: '',
    noMore: '',
    error: 'Something went wrong.',
  },
});

mountInterceptor();
sync(store, router);

moment.tz.setDefault('Poland');
window.EventBus = new Vue();

const app = new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');

export default app;

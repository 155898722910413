<template>
  <div class="notificaton__wrapper" @click="goToActivity">
    <img v-if="notification.important" src="../../../assets/images/flag.svg" class="notification__image" />
    <div v-else class="notification__circle">
      <div class="notification__circle__text">
        {{ notification.text.charAt(0) }}
      </div>
    </div>
    <div class="notification__content">
      <div>{{ notification.text }} </div>
      <div class="notification__time">
        {{ formattedTime(notification.happenedAt) }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import TimeFormatter from '../../../mixins/time_formatter';

export default {
  mixins: [TimeFormatter],
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToActivity() {
      this.$router.push({ name: _.snakeCase(this.notification.resourceType), params: { id: this.notification.resourceId }});
    },
  }
};
</script>

<style lang="scss" scoped>
  .notificaton__wrapper {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    padding: 15px 25px;
    width: 100%;

    &:hover {
      background: rgba(112, 115, 142, 0.1);
    }
  }

  .notification__image {
    margin-top: 5px;
  }

  .notification__circle {
    background: #76B097;
    border-radius: 100%;
    color: white;
    margin-top: 3px;
  }

  .notification__circle__text {
    width: 27px;
    height: 27px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .dropdown-divider {
    margin: 0;
  }

  .notification__content {
    color: #3D405B;
    margin-left: 10px;
    white-space: normal;
  }

  .notification__time {
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px;
  }
</style>

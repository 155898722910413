<template>
  <CDataTable
    :items="items.items"
    :fields="items.fields"
    :header="false"
    class="table-borderless"
    hover
  >
    <template #fullName="{item}">
      <td>
        {{item.firstName}} {{item.lastName}}
      </td>
    </template>
    <template #role="{item}">
      <td>
        {{ lowerCaseRole(item.role) }}
      </td>
    </template>
    <template #confirmedAt="{item}">
      <td class="team-table-invitation-sent">
        {{ item.confirmedAt ? '' : $t('settings.invitation_has_been_sent') }}
      </td>
    </template>
    <template #icon="{item}">
      <td class="py-2">
        <div class="delete-icon" @click="showDeletionModal(item.id)">
          x
        </div>
        <CustomModal
          :show-modal="deleteRecruiterId === item.id"
          @modalClosed="deleteRecruiterId = null"
        >
          <template slot="body-wrapper">
            <span>{{ $t('settings.delete_message') }}</span>
            <p>{{ item.firstName }} {{ item.lastName }}, {{ lowerCaseRole(item.role) }}?</p>
          </template>
          <template slot="footer">
            <CButton
              color="primary-dark"
              shape="pill"
              @click="cancel"
            >
              {{ $t('common.cancel') }}
            </CButton>
            <CButton
              shape="pill"
              class="team-table-confirm"
              @click="confirmDeletion(item.id)"
            >
              {{ $t('common.confirm') }}
            </CButton>
          </template>
        </CustomModal>
      </td>
    </template>
  </CDataTable>
</template>

<script>
import _ from 'lodash';
import CustomModal from '../custom/CustomModal.vue';

export default {
  components: { CustomModal },
  data () {
    return {
      deleteRecruiterId: null,
    }
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  methods: {
    lowerCaseRole(role) {
      return _.lowerCase(this.$t(`settings.role.${role}`));
    },
    showDeletionModal(id) {
      this.deleteRecruiterId = id
    },
    cancel() {
      this.deleteRecruiterId = null;
    },
    async confirmDeletion(id) {
      this.deleteRecruiterId = null;
      await this.$store.dispatch('teamMembers/deleteMember', id);
      this.$emit('recruiterDeleted');
    },
  },
};
</script>

<style lang="scss" scoped>
  .team-table-invitation-sent {
    color: #539B7B;
    text-align: center;
  }

  .team-table-confirm {
    border: 1px solid #F7A123;
    color: #F7A123;
    margin-left: 8px;
  }

  .delete-icon {
    color: #FE461C;
    cursor: pointer;
    font-weight: 600;
  }

  ::v-deep .table td {
    padding-left: 0;
  }
</style>

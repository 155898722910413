<template>
  <div class="login-wrapper">
    <img src="../../../assets/images/logo.svg" class="login-logo" />

    <div class="login-greetings">
      {{ $t('login.nice_to_see_you') }}
    </div>

    <LoginForm />

    <RouterLink :to="{ name: 'forgot_password' }" class="login-router-link">
      {{ $t('forgot_password.forgot') }}
    </RouterLink>
  </div>
</template>
<script>
import LoginForm from '../../elements/auth/LoginForm.vue';

export default {
  components: { LoginForm },
};
</script>

<style lang="scss" scoped>
  .login-wrapper {
    padding-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .login-greetings {
    font-size: 24px;
    margin-top: 20px;
  }

  .login-router-link {
    margin-top: 15px;
    text-decoration-line: underline;
    color: #3D405B;
  }
</style>

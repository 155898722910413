import { getField, updateField } from 'vuex-map-fields';

export const basicFormState = () => ({
  form: {},
  errors: {},
  loading: false,
  success: false,
});

export const basicFormAction = async (context, method) => {
  context.commit('setLoading', true);

  try {
    await method();

    context.commit('setSuccess', true);
    context.commit('setErrors', {});
  } catch ({ errors }) {
    context.commit('setSuccess', false);
    context.commit('setErrors', errors);
  }

  context.commit('setLoading', false);
};

const getters = {
  getField,
};

const mutations = {
  setErrors(state, errors) {
    state.errors = errors;
  },
  setSuccess(state, success) {
    state.success = success;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  updateField,
};

export const basicFormStore = {
  namespaced: true,
  state: basicFormState(),
  getters,
  mutations,
};

import _ from 'lodash';
import { basicFormAction, basicFormStore, basicFormState } from './basic';
import recruitmentProcessesRepository from '../../repositories/recruitment_processes_repository';

const formState = () => ({
  form: {
    salary: '',
    startDate: '',
  },
});

const mutations = {
  init(state) {
    _.assign(state, basicFormState(), formState());
  },
};

const actions = {
  async hire(context, { id }) {
    await basicFormAction(context, async () => {
      await recruitmentProcessesRepository.hire(id, context.state.form);
    });
  },
};

export default _.defaultsDeep({
  state: basicFormState(),
  mutations,
  actions,
}, basicFormStore);

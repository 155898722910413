<template>
  <div>
    <SecondaryTopBar />
    <div class="settings-wrapper">
      <div class="settings-header">
        {{ $t('settings.hello') }}, {{ $auth.user().firstName }}
      </div>
      <div class="settings-subheader">
        {{ $t(`settings.hello_description.${$auth.user().role}`) }}
      </div>
      <TeamManagement v-if="isManager" />
      <RecruiterSettings />
    </div>
  </div>
</template>

<script>
import TeamManagement from '../elements/settings/TeamManagement.vue';
import RecruiterSettings from '../elements/settings/RecruiterSettings.vue';
import SecondaryTopBar from '../elements/layouts/SecondaryTopBar.vue';

export default {
  components: {
    TeamManagement,
    RecruiterSettings,
    SecondaryTopBar,
  },
  computed: {
    isManager() {
      return this.$auth.user().role === 'manager' || this.$auth.user().role === 'staff';
    },
  },
};
</script>

<style lang="scss" scoped>
  .settings-wrapper {
    padding-right: 250px;
  }

  .settings-header {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .settings-subheader {
    line-height: 1.73;
  }

  .settings-your-data {
    margin-top: 70px;
  }
</style>

<template>
  <form class="login-form-wrapper" @submit.prevent="submit">
    <div>
      <CustomInput
        v-model="email"
        :label="$t('login.email.label')"
        :placeholder="$t('login.email.placeholder')"
        :required="true"
        class="underline-input login-form-input"
      />

      <CustomInput
        v-model="password"
        :label="$t('login.password.label')"
        :placeholder="$t('login.password.placeholder')"
        :required="true"
        type="password"
        class="underline-input login-form-input"
      />

      <CInputCheckbox
        :checked.sync="rememberMe"
        :label="$t('login.remember_me')"
        inline
      />
    </div>

    <CButton
      type="submit"
      color="primary-dark"
      shape="pill"
      :disabled="loading"
      class="text-white login-button"
    >
      {{ $t('login.sign_in') }}
    </CButton>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import CustomInput from '../../elements/custom/CustomInput.vue';

const { mapFields } = createHelpers({
  getterType: 'forms/login/getField',
  mutationType: 'forms/login/updateField',
});

export default {
  components: {
    CustomInput,
  },
  computed: {
    ...mapFields([
      'form.email',
      'form.password',
      'form.rememberMe',
    ]),
    ...mapState({
      errors: state => state.forms.login.errors,
      loading: state => state.forms.login.loading,
    }),
  },
  watch: {
    errors(value) {
      if (value.length > 0) {
        this.$store.dispatch('toaster/display', {
          messages: [{ message: value.join(', ') }],
          autoHide: 5000,
        });
      }
    },
  },
  created() {
    this.$store.commit('forms/login/init');
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      this.$store.commit('forms/login/setLoading', true);

      try {
        await this.$auth.login({
          data: {
            email: this.email,
            password: this.password,
          },
          rememberMe: this.rememberMe,
          redirect: {
            name: 'dashboard',
          },
        });
      } catch (e) {
        this.$store.commit('forms/login/setErrors', e.errors);
      }
      this.$store.commit('forms/login/setLoading', false);
    },
  },
};
</script>
<style lang="scss" scoped>
  .login-form-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .login-button {
    font-size: 18px;
    margin-top: 30px;
    padding: 8px 30px;
  }

  .login-form-input {
    margin-top: 30px;
  }
</style>

<template>
  <div class="custom-input-select__wrapper">
    <CustomInput
      :value="value"
      :name="name"
      autocomplete="off"
      @input="updateValue($event)"
      @keydown="keydownEvent"
    />
    <div v-if="showOptions && filteredOptions" class="custom-input-select__bg" @click="hideOptions" />
    <div v-if="showOptions && filteredOptions" class="custom-input-select__options" ref="optionsContainer">
      <div
        v-for="option in filteredOptions"
        :key="option.id"
        class="custom-input-select__option"
        :class="{ 'custom-input-select__option-selected': option.id === selectedId }"
        @click="selectOption(option.name)"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import CustomInput from './CustomInput.vue';
import Fuse from 'fuse.js';

const fuse = new Fuse([], { keys: ['name'] });

export default {
  components: {
    CustomInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptions: false,
      selectedId: 0,
      index: -1,
    };
  },
  computed: {
    filteredOptions() {
      return (this.value && this.value.length) ? fuse.search(this.value).map(({ item }) => item) : null;
    },
  },
  watch: {
    index(value) {
      if (value === 0 || value > 1) {
        this.$nextTick(() => {
          let indexValue = value > 1 ? value - 1 : value;
          this.$refs.optionsContainer.scrollTop = indexValue * 31;
        });
      }
    },
  },
  created() {
    fuse.setCollection(this.options);
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
      this.showOptions = true;
      this.selectedId = 0;
      this.index = -1;
    },
    selectOption(option) {
      this.$emit('input', option);
      this.showOptions = false;
      this.selectedId = 0;
      this.index = -1;
    },
    hideOptions() {
      this.showOptions = false;
    },
    selectByKey() {
      if (!this.filteredOptions || this.selectedId === 0) {
        return;
      }

      this.selectOption(_.find(this.filteredOptions, { id: this.selectedId }).name);
    },
    goDown() {
      if (!this.filteredOptions || this.index >= this.filteredOptions.length - 1) {
        return;
      }

      this.selectedId = this.filteredOptions[++this.index].id;
    },
    goUp() {
      if (!this.filteredOptions || this.index <= 0) {
        return;
      }

      this.selectedId = this.filteredOptions[--this.index].id;
    },
    keydownEvent(event) {
      switch (event.keyCode) {
        case 13:
          this.selectByKey();
          break;
        case 38:
          this.goUp();
          break;
        case 40:
          this.goDown();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-input-select__wrapper {
    position: relative;
    padding-right: 0;
  }

  .custom-input-select__options {
    background-color: #fff;
    border: 1px solid #d8dbe0;
    border-radius: 0 0 5px 5px;
    margin-top: -17px;
    max-height: 160px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 2;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid transparent;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .custom-input-select__option {
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #70738E;
      color: #f5f5f5;
    }
  }

  .custom-input-select__option-selected {
    background-color: #70738E;
    color: #f5f5f5;
  }

  .custom-input-select__bg {
    position: fixed;
    top: -100vh;
    left: -100vw;
    right: -100vw;
    bottom: -100vh;
    z-index: 1;
  }
</style>

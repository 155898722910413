<template>
  <div class="forgot-password-wrapper">
    <img src="../../../assets/images/logo.svg" class="login-logo forgot-password-img" />

    <template v-if="success">
      <div class="forgot-passoword--success">
        {{ $t('forgot_password.we_sent_you_email') }}
      </div>
    </template>
    <template v-else>
      <div>
        {{ $t('forgot_password.enter_email') }}
      </div>

      <ForgotPasswordForm />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ForgotPasswordForm from '../../elements/auth/ForgotPasswordForm.vue';

export default {
  components: { ForgotPasswordForm },
  computed: {
    ...mapState({
      success: state => state.forms.forgotPassword.success,
    })
  }
};
</script>

<style lang="scss" scoped>
  .forgot-password-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 200px;
  }

  .forgot-password-img {
    margin-bottom: 20px;
  }

  .forgot-passoword--success {
    font-size: 18px;
    margin-top: 30px;
  }
</style>

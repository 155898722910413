<template>
  <CustomModal :show-modal="showModal" size="lg" @modalClosed="closeModal">
    <template slot="body-wrapper">
      <div class="details-wrapper">
        <p class="details-title">{{ jobOffer.position }}</p>
        <span class="details-subtitle">
          {{ $t('companies.job_offers.job_offer_form.recruiter.label') }}:
          {{ jobOffer.recruiter.name }}
        </span>
        <div class="row details-details">
          <div class="col-6">
            <p>{{ $t('companies.job_offers.job_offer_form.industry') }}: <span>{{ jobOffer.industry.name }}</span></p>
            <p>{{ $t('companies.job_offers.job_offer_form.specialization') }}: <span>{{ jobOffer.specialization.name }}</span></p>
            <p>{{ $t('companies.job_offers.job_offer_form.salary_range') }}:
              <p v-for="item in jobOffer.employmentTypes" :key="item.id">
                <span>{{ `${item.name}: ${item.grossSalaryMin} - ${item.grossSalaryMax}` }}</span>
              </p>
            </p>
            <p>{{ $t('companies.job_offers.job_offer_form.location') }}: <span>{{ jobOffer.location.name }}</span></p>
            <p class="details-additional-information">{{ $t('companies.job_offers.additional_requirements') }}</p>
            <div v-if="!isEmpty(jobOffer.requirements)">
              <div
                v-for="item in jobOffer.requirements"
                :key="item.id"
                class="details-additional-information-item"
              >
                {{ item.content }}
              </div>
            </div>
            <div v-else class="details-no-data">
              {{ $t('common.not_specified') }}
            </div>
            <p class="details-additional-information">{{ $t('companies.job_offers.additional_information') }}</p>
            <div v-if="!isEmpty(jobOffer.details)">
              <div
                v-for="item in jobOffer.details"
                :key="item.id"
                class="details-additional-information-item"
              >
                {{ item.content }}
              </div>
            </div>
            <div v-else class="details-no-data">
              {{ $t('common.not_specified') }}
            </div>
          </div>
          <div class="col-6">
            <p>{{ $t('companies.job_offers.benefits') }}:</p>
            <div v-if="!isEmpty(jobOffer.benefits)" class="row details-container">
              <SelectButton
                v-for="item in jobOffer.benefits"
                :key="item.id"
                :label="item.name"
                :checked="true"
                :disabled="true"
                class="details-select-button" />
            </div>
            <div v-else class="details-no-data">
              {{ $t('common.not_specified') }}
            </div>
            <p>{{ $t('companies.job_offers.required_skills') }}:</p>
            <div class="row details-container">
              <SkillButton
                v-for="item in jobOffer.requiredSkills"
                :key="item.id"
                :skill="item"
                :disabled="true"
                class="details-skill-button" />
            </div>
            <p>{{ $t('companies.job_offers.desirable_skills') }}:</p>
            <div v-if="!isEmpty(jobOffer.desirableSkills)" class="row details-container">
              <SelectButton
                v-for="item in jobOffer.desirableSkills"
                :key="item.id"
                :label="item.name"
                :checked="true"
                :disabled="true"
                class="details-select-button" />
            </div>
            <div v-else class="details-no-data">
              {{ $t('common.not_specified') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer"><div></div></template>
  </CustomModal>
</template>

<script>
import _ from 'lodash';
import CustomModal from '../custom/CustomModal.vue';
import SelectButton from '../custom/SelectButton.vue';
import SkillButton from '../custom/SkillButton.vue';

export default {
  components: {
    CustomModal,
    SkillButton,
    SelectButton,
  },
  props: {
    jobOffer: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
     this.$emit('modalClosed');
    },
    isEmpty(list) {
      return _.isEmpty(list);
    },
  },
};
</script>

<style lang="scss" scoped>
  .details-wrapper {
    text-align: left;
    padding: 20px 60px;
  }

  .details-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: -6px;
  }

  .details-subtitle {
    font-size: 12px;
    color: #C4C4C4;
  }

  .details-details {
    margin-top: 30px;
  }

  p {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;

    span {
      font-weight: normal;
      margin-left: 5px;
    }
  }

  .details-select-button {
    margin: 3px;
  }

  .details-container {
    align-items: flex-start;
    margin: 10px 0 30px 0;
  }

  .details-additional-information {
    margin-top: 50px;
  }

  .details-additional-information-item {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 10px;
  }

  .details-no-data {
    color: #C4C5CD;
    font-size: 14px;
    margin: -5px 0 10px 0;
  }
</style>

<template>
  <div class="employment-type__wrapper">
    <SelectButton
      :label="employmentType.name"
      :checked="isChecked"
      @toggle="addOrRemoveEmploymentType"
      class="employment-type__button select-button--employment-types"
    />
    <div v-if="isChecked" class="employment-type__salary-range">
      <div>{{ $t('companies.job_offers.job_offer_form.from') }}</div>
      <CustomInput
        type="number"
        min="0"
        name="from"
        :value="currentGrossSalaryMin"
        @input="updateEmploymentTypeGrossSalaryMin($event)"
        class="employment-type__number-input"
      />
      <div>{{ $t('companies.job_offers.job_offer_form.to') }}</div>
      <CustomInput
        type="number"
        min="0"
        name="to"
        :value="currentGrossSalaryMax"
        @input="updateEmploymentTypeGrossSalaryMax($event)"
        class="employment-type__number-input"
      />
    </div>
  </div>
</template>

<script>
import SelectButton from '../custom/SelectButton.vue';
import CustomInput from '../custom/CustomInput.vue';

export default {
  components: {
    SelectButton,
    CustomInput,
  },
  props: {
    employmentType: {
      type: Object,
      required: true,
    },
    employmentTypes: {
      type: Array,
      required: true,
    }
  },
  computed: {
    isChecked() {
      return this.currentIndex !== -1;
    },
    currentEmploymentType() {
      if (this.isChecked) {
        return this.employmentTypes[this.currentIndex];
      }

      return {};
    },
    currentGrossSalaryMin() {
      if (this.currentEmploymentType.grossSalaryMin) {
        return this.currentEmploymentType.grossSalaryMin.toString();
      }

      return null;
    },
    currentGrossSalaryMax() {
      if (this.currentEmploymentType.grossSalaryMax) {
        return this.currentEmploymentType.grossSalaryMax.toString();
      }

      return null;
    },
    currentIndex() {
      return this.employmentTypes.findIndex(x => x.id  === this.employmentType.id)
    },
  },
  methods: {
    addOrRemoveEmploymentType() {
      this.$emit('toggleEmploymentType');
    },
    updateEmploymentTypeGrossSalaryMin(grossSalaryMin) {
      this.$emit('updateEmploymentTypeGrossSalaryMin', grossSalaryMin);
    },
    updateEmploymentTypeGrossSalaryMax(grossSalaryMax) {
      this.$emit('updateEmploymentTypeGrossSalaryMax', grossSalaryMax);
    }
  },
};
</script>

<style lang="scss" scoped>
  .employment-type__wrapper {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
  }

  .employment-type__salary-range {
    align-items: center;
    display: flex;
  }

  .employment-type__number-input {
    height: 33px;
    width: 75px;
    margin: 0 5px;

    ::v-deep input[type="number"].form-control {
      padding: 4px;

      &:focus {
        box-shadow: none;
      }
    }
  }
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from './components/sections/Dashboard.vue';
import JobOffers from './components/sections/JobOffers.vue';
import JobOffer from './components/sections/JobOffer.vue';
import AddJobOffer from './components/sections/AddJobOffer.vue';
import EditJobOffer from './components/sections/EditJobOffer.vue';
import Messages from './components/sections/Messages.vue';
import AuthLogin from './components/sections/auth/Login.vue';
import AuthForgotPassword from './components/sections/auth/ForgotPassword.vue';
import AuthResetPassword from './components/sections/auth/ResetPassword.vue';
import AuthAcceptInvitation from './components/sections/auth/AcceptInvitation.vue';
import Settings from './components/sections/Settings.vue';
import RecruitmentProcess from './components/sections/RecruitmentProcess.vue';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  base: '/company',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: { auth: true }
    },
    {
      path: '/job_offers',
      name: 'job_offers',
      component: JobOffers,
      meta: { auth: true }
    },
    {
      path: '/job_offers/new',
      name: 'add_job_offer',
      component: AddJobOffer,
      meta: { auth: true }
    },
    {
      path: '/job_offers/:id',
      name: 'job_offer',
      component: JobOffer,
      meta: { auth: true },
    },
    {
      path: '/job_offers/:id/edit',
      name: 'edit_job_offer',
      component: EditJobOffer,
      meta: { auth: true }
    },
    {
      path: '/recruitment_processes/:id',
      name: 'recruitment_process',
      component: RecruitmentProcess,
      meta: { auth: true },
    },
    {
      path: '/messages',
      name: 'messages',
      component: Messages,
      meta: { auth: true }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { auth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: AuthLogin,
      meta: { auth: false, withoutLayout: true },
    },
    {
      path: '/forgot_password',
      name: 'forgot_password',
      component: AuthForgotPassword,
      meta: { auth: false, withoutLayout: true },
    },
    {
      path: '/reset_password',
      name: 'reset_password',
      component: AuthResetPassword,
      meta: { auth: false, withoutLayout: true },
    },
    {
      path: '/accept_invitation/:token/:email',
      name: 'accept_invitation',
      component: AuthAcceptInvitation,
      meta: { auth: false, withoutLayout: true },
    },
  ],
});

import httpClient from '../utils/http_client';

export default {
  async index() {
    const response = await httpClient.get('/activities');

    return response.data;
  },

  async getNewNotificationsCount() {
    const response = await httpClient.get('/activities/new_activities_count');

    return response.data;
  },

  async markNotificationsAsSeen() {
    const response = await httpClient.put('/activities/mark_activities_as_seen');

    return response.data;
  },

  async getConversationsStatus() {
    const response = await httpClient.get('/conversations/status');

    return response.data;
  }
};

<template>
  <div>
    <label class="typo__label">{{ label }}</label>
    <Multiselect
      :value="value"
      :options="options"
      :multiple="multiple"
      :close-on-select="closeOnSelect"
      :preserve-search="preserveSearch"
      :placeholder="placeholder"
      :disabled="disabled"
      :label="displayName"
      :track-by="trackBy"
      @select="$emit('select', $event)"
      @remove="$emit('remove', $event)"
      @open="$emit('open', $event)"
      @close="$emit('close', $event)"
    />
    <div v-if="hasErrors" class="select-error"> {{ errors.join(', ') }}</div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    preserveSearch: {
      type: Boolean,
      default: false,
    },
    displayName: {
      type: String,
      default: 'name',
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    hasErrors() {
      return this.errors && this.errors.length > 0;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  ::v-deep .multiselect__tag {
    background-color: #70738E;
  }

  ::v-deep .multiselect__tag-icon {
    &::after {
      color: #fff;
    }

    &:hover, &:focus {
      background: #3D405B;
    }
  }

  ::v-deep .multiselect__option--highlight {
    background: #70738E;
  }

  ::v-deep .multiselect__option--highlight::after {
    background: #70738E;
    content: '';
  }

  ::v-deep .multiselect__option {
    font-size: 14px;
  }

  ::v-deep .multiselect__input,
  ::v-deep .multiselect__single {
    font-size: 14px;
  }

  .select-error {
    color: #FF3000;
    font-size: 12px;
    margin: 2px 0 0 1px;
  }
</style>

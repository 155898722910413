<template>
  <CustomModal :show-modal="showModal" @modalClosed="closeModal">
    <template slot="body-wrapper">
      <span>{{ $t('companies.job_offers.job_offer_form.do_you_want_to_proceed') }}</span>
    </template>
    <template slot="footer">
      <CButton
        color="primary-dark"
        shape="pill"
        @click="cancel"
      >
        {{ $t('common.cancel') }}
      </CButton>
      <CButton
        shape="pill"
        class="confirmation-dialog__confirm"
        @click="confirm"
      >
        {{ $t('common.confirm') }}
      </CButton>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '../custom/CustomModal.vue';

export default {
  components: {
    CustomModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    event: {
      type: String,
      requried: true,
    },
  },
  methods: {
    cancel() {
      this.closeModal();
    },
    confirm() {
      this.closeModal()
      this.$emit('confirmed', this.event);
    },
    closeModal() {
      this.$emit('modalClosed');
    },
  },
};
</script>

<style lang="scss" scoped>
  .confirmation-dialog__confirm {
    border: 1px solid #F7A123;
    color: #F7A123;
    margin-left: 8px;
  }
</style>

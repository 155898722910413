<template>
  <CustomModal :show-modal="showModal" @modalClosed="closeModal">
    <template slot="body-wrapper">
      <span>{{ $t('companies.job_offers.archive.are_you_sure') }}</span>
      <p>{{ jobOffer.position }}, {{ jobOffer.location.name }}?</p>
    </template>
    <template slot="footer">
      <CButton
        color="primary-dark"
        shape="pill"
        @click="closeModal"
      >
        {{ $t('common.cancel') }}
      </CButton>
      <CButton
        shape="pill"
        class="offer-archive-confirm"
        @click="confirmArchive"
      >
        {{ $t('common.confirm') }}
      </CButton>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '../custom/CustomModal.vue';

export default {
  components: {
    CustomModal,
  },
  props: {
    jobOffer: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('modalClosed');
    },
    confirmArchive() {
      this.$emit('archiveConfirmed');
    },
  },
};
</script>

<style lang="scss" scoped>
  .offer-archive-confirm {
    border: 1px solid #F7A123;
    color: #F7A123;
    margin-left: 8px;
  }
</style>

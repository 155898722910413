<template>
  <div class="recent-feedback__wrapper">
    <div class="recent-feedback__feedback">
      {{ formattedFeedback }} 
      <CButton
        v-if="showButton"
        class="recent-feedback__show-more"
        @click.stop="toggleShowWholeFeedback"
      >
        ({{ feedbackButtonLabel }})
      </CButton>
    </div>
    <div class="recent-feedback__details">
      <div>
        {{ $t('dashboard.offer') }}:
        <span class="recent-feedback__bold">
          {{ item.position }}, {{ item.locationName }}
        </span>
      </div>
      <div>
        {{ $t('dashboard.recruiter') }}:
        <span class="recent-feedback__bold">
          {{ item.recruiterName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  data() {
    return {
      showWholeFeedback: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedFeedback() {
      if (this.showWholeFeedback) {
        return _.escape(this.item.feedback);
      }
      return _.escape(_.truncate(this.item.feedback, { length: 70 }));
    },
    feedbackButtonLabel() {
      if (this.showWholeFeedback) {
        return this.$t('dashboard.show_less');
      }

      return this.$t('dashboard.show_more');
    },
    showButton() {
      return _.size(this.item.feedback) >= 70;
    },
  },
  methods: {
    toggleShowWholeFeedback() {
      this.showWholeFeedback = !this.showWholeFeedback;
    },
  },
}
</script>

<style lang="scss" scoped>
  .recent-feedback__wrapper {
    border: 1px solid #E8E8EA;
    box-shadow: 0px 0px 6px rgba(61, 64, 91, 0.15);
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 15px;
    width: 100%;
  }

  .recent-feedback__show-more {
    box-shadow: none;
    display: inline;
    margin-bottom: 3px;
    padding: 0;

    &:hover {
      color: #FF7252;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  .recent-feedback__feedback {
    font-style: italic;
    margin-bottom: 15px;
  }

  .recent-feedback__details {
    font-size: 12px;
  }

  .recent-feedback__bold {
    font-weight: bold;
  }
</style>

<template>
  <div class="reset-password-wrapper">
    <img src="../../../assets/images/logo.svg" class="login-logo" />

    <ResetPasswordForm />
  </div>
</template>

<script>
import ResetPasswordForm from '../../elements/auth/ResetPasswordForm.vue';

export default {
  components: {
    ResetPasswordForm,
  },
};
</script>

<style lang="scss" scoped>
  .reset-password-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 200px;
  }
</style>

import httpClient from '../utils/http_client';

export default {
  async forgotPassword(data) {
    const response = await httpClient.post('/auth/password', data);

    return response.data;
  },

  async changePassword(data) {
    const response = await httpClient.put('/auth/password', data);

    return response.data;
  },
};

<template>
  <div>
    <CustomToast />
    <slot />
  </div>
</template>

<script>
import CustomToast from '../elements/custom/CustomToast.vue';
export default {
  components: {
    CustomToast,
  },
  created() {
    this.$store.commit('toaster/clear');
  },
};
</script>

<template>
  <div>
    <SecondaryTopBar />
    <BasicInformation />
    <Requirements />
    <SubmitBar />
  </div>
</template>

<script>
import SecondaryTopBar from '../layouts/SecondaryTopBar.vue';
import BasicInformation from './BasicInformation';
import Requirements from './Requirements';
import SubmitBar from './SubmitBar';

export default {
  components: {
    SecondaryTopBar,
    BasicInformation,
    Requirements,
    SubmitBar,
  },
};
</script>

<template>
  <div v-if="!loading" class="details__wrapper">
    <div v-if="currentConversationId" class="details__contact-data">
      <div class="details__title">
        {{ $t('messages.contact_data') }}
      </div>
      <div v-if="currentConversation.employeePhoneNumber">
        <span>{{ $t('messages.phone') }}</span>:
        <span class="details__data">{{ currentConversation.employeePhoneNumber }}</span>
      </div>
      <div v-if="currentConversation.employeeEmail">
        <span>{{ $t('messages.email') }}</span>:
        <span class="details__data">{{ currentConversation.employeeEmail }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      conversations: state => state.messages.conversations,
      currentConversationId: state => state.messages.currentConversationId,
      loading: state => state.messages.loading,
    }),
    currentConversation() {
      return this.currentConversationId ? _.find(this.conversations, { id: this.currentConversationId }) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .details__wrapper {
    border-left: 1px solid #ECEDF4;
    padding: 30px;
  }

  .details__title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .details__data {
    font-weight: 700;
  }
</style>

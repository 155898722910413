<template>
  <div v-if="!loading">
    <NavBar :items="[{ key: 'basic_information' }]" :show-counter="false" class="border-navbar" />
    <div v-if="areOfferOptionsPresent" class="row basic-information-wrapper">
      <div class="col-4">
        <CustomSelect
          :label="$t('companies.job_offers.job_offer_form.industry')"
          :placeholder="$t('common.select_from_list')"
          :value="selectedIndustry"
          :options="offerOptions.industries"
          :errors="errors.industryId"
          class="basic-information-select--industry"
          @select="selectIndustry($event)"
          @open="clearIndustrySelection"
        />
        <CustomSelect
          :label="$t('companies.job_offers.job_offer_form.specialization')"
          :placeholder="$t('common.select_from_list')"
          :value="selectedSpecialization"
          :options="specializations"
          :disabled="!industryId"
          :errors="errors.specializationId"
          class="basic-information-select--specialization"
          @select="selectSpecialization($event)"
          @open="clearSpecializationSelection"
        />
        <CustomInput
          v-model="position"
          :label="$t('companies.job_offers.job_offer_form.position')"
          :placeholder="$t('companies.job_offers.job_offer_form.it_will_show_as')"
          :errors="errors.position"
          name="position"
          class="basic-information-select--position"
        />
        <div class="basic-information-employment-types__title">
          {{ $t('companies.job_offers.job_offer_form.employment_type') }}
        </div>
        <div class="basic-information-employment-types__subtitle">
          {{ $t('companies.job_offers.job_offer_form.use_gross_salary') }}
        </div>
        <div v-if="errors.employmentTypes" class="basic-information-errors">
          {{ errors.employmentTypes.join(', ') }}
        </div>
        <EmploymentType
          v-for="item in offerOptions.employmentTypes"
          :key="item.id"
          :employment-type="item"
          :employment-types="employmentTypes"
          @toggleEmploymentType="toggleEmploymentType(item.id)"
          @updateEmploymentTypeGrossSalaryMin="updateEmploymentTypeGrossSalaryMin(item.id, $event)"
          @updateEmploymentTypeGrossSalaryMax="updateEmploymentTypeGrossSalaryMax(item.id, $event)"
        />
      </div>
      <div class="col-4">
        <CustomSelect
          :multiple="true"
          :label="$t('companies.job_offers.job_offer_form.location')"
          :placeholder="$t('common.select_from_list')"
          :value="selectedLocations"
          :options="offerOptions.locations"
          :close-on-select="false"
          :errors="errors.locationIds"
          class="basic-information-select--location"
          @select="addLocationId($event)"
          @remove="removeLocationId($event)"
        />
        <CustomFields
          :items="requirements"
          :title="$t('companies.job_offers.additional_requirements')"
          :allow-edit="true"
          @add="addNewRequirement($event)"
          @remove="removeRequirement($event)"
          @edit="editRequirement($event)"
          class="basic-information-requirements custom-fields--requirements"
        />
        <CustomFields
          :items="details"
          :title="$t('companies.job_offers.additional_information')"
          :allow-edit="true"
          @add="addNewDetail($event)"
          @remove="removeDetail($event)"
          @edit="editDetail($event)"
          class="basic-information-details custom-fields--details"
        />
      </div>
      <div class="col-4 basic-information-benefits">
        <div class="basic-information-benefits__title">{{ $t('companies.job_offers.job_offer_form.benefits') }}</div>
        <SelectButton
          v-for="item in offerOptions.benefits"
          v-bind:key="item.id"
          :label="item.name"
          :checked="benefitIds.indexOf(item.id) > -1"
          class="basic-information-button"
          @toggle="addOrRemoveBenefit(item.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import NavBar from '../custom/NavBar.vue';
import SelectButton from '../custom/SelectButton.vue';
import CustomInput from '../custom/CustomInput.vue';
import CustomSelect from '../custom/CustomSelect.vue';
import CustomFields from '../custom/CustomFields.vue';
import EmploymentType from './EmploymentType.vue';

const { mapFields } = createHelpers({
  getterType: 'forms/jobOffer/getField',
  mutationType: 'forms/jobOffer/updateField',
});

export default {
  components: {
    NavBar,
    SelectButton,
    CustomInput,
    CustomSelect,
    CustomFields,
    EmploymentType,
  },
  computed: {
    ...mapFields([
      'form.position',
      'form.employmentTypes',
      'form.benefitIds',
      'form.requirements',
      'form.details',
      'form.industryId',
      'form.specializationId',
      'form.locationIds',
    ]),
    ...mapState({
      errors: state => state.forms.jobOffer.errors,
      success: state => state.forms.jobOffer.success,
      loading: state => state.forms.jobOffer.loading,
      offerOptions: state => state.jobOffers.offerOptions,
    }),
    areOfferOptionsPresent() {
      return !_.isEmpty(this.offerOptions);
    },
    specializations() {
      if (!_.isEmpty(this.offerOptions.specializations) && this.industryId) {
        return _.filter(this.offerOptions.specializations, { 'industryId': this.industryId });
      } else {
        return [];
      }
    },
    selectedIndustry() {
      return _.find(this.offerOptions.industries, { id: this.industryId });
    },
    selectedSpecialization() {
      return _.find(this.offerOptions.specializations, { id: this.specializationId });
    },
    selectedLocations() {
      return _.filter(this.offerOptions.locations, (location) => { return _.includes(this.locationIds, location.id) });
    },
  },
  watch: {
    industryId(newValue, oldValue) {
      if (oldValue) {
        this.specializationId = '';
      }
    },
  },
  async created() {
    this.$store.commit('forms/jobOffer/init');
    await this.$store.dispatch('jobOffers/fetchOfferOptions');
  },
  methods: {
    addOrRemoveBenefit(id) {
      this.addOrRemoveItem(this.benefitIds, id);
    },
    toggleEmploymentType(id) {
      let index = this.employmentTypes.findIndex(x => x.id  === id);
      if (index === -1) {
        this.employmentTypes.push({ id: id });
      } else {
        this.employmentTypes.splice(index, 1);
      }
    },
    updateEmploymentTypeGrossSalaryMin(id, grossSalaryMin) {
      let index = this.employmentTypes.findIndex(x => x.id  === id);
      this.employmentTypes[index].grossSalaryMin = grossSalaryMin;
    },
    updateEmploymentTypeGrossSalaryMax(id, grossSalaryMax) {
      let index = this.employmentTypes.findIndex(x => x.id  === id);
      this.employmentTypes[index].grossSalaryMax = grossSalaryMax;
    },
    addOrRemoveItem(list, item){
      let index = list.findIndex(x => x  === item);
      if (index === -1) {
        list.push(item);
      } else {
        list.splice(index, 1);
      }
    },
    addNewRequirement(value) {
      this.requirements.unshift({ content: value });
    },
    removeRequirement(value) {
      this.requirements.splice(value.index, 1);
    },
    editRequirement(value) {
      let content = value.updatedContent;
      let index = value.index;
      this.requirements[index].content = content;
    },
    addNewDetail(value) {
      this.details.unshift({ content: value });
    },
    removeDetail(value) {
      this.details.splice(value.index, 1);
    },
    editDetail(value) {
      let content = value.updatedContent;
      let index = value.index;
      this.details[index].content = content;
    },
    selectIndustry(industry) {
      this.industryId = industry.id;
    },
    selectSpecialization(specialization) {
      this.specializationId = specialization.id;
    },
    addLocationId(location) {
      this.locationIds.push(location.id);
    },
    removeLocationId(location) {
      let index = this.locationIds.findIndex(x => x  === location.id);
      this.locationIds.splice(index, 1);
    },
    clearIndustrySelection() {
      if (this.industryId) {
        this.industryId = null;
      }
    },
    clearSpecializationSelection() {
      if (this.specializationId) {
        this.specializationId = null;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .basic-information-wrapper {
    margin-top: 30px;
    text-align: left;
    padding-bottom: 50px;
  }

  .basic-information-select--industry,
  .basic-information-select--specialization,
  .basic-information-select--position,
  .basic-information-select--location {
    width: 100%;
    padding: 0 60px 20px 0;
    margin-bottom: 20px;
  }

  .basic-information-select--position {
    ::v-deep .form-group {
      margin-bottom: 10px;
    }

    ::v-deep .form-control {
      min-height: 40px;
    }
  }

  .basic-information-additional {
    justify-content: space-between;
    padding: 0 45px 0 15px;
    width: 100%;
  }

  .basic-information-additional-button {
    color: #FF7252;
    font-size: 12px;
    padding: 0 0.75rem;
    text-decoration: underline;

    &:focus {
      box-shadow: none;
    }
  }

  .basic-information-note {
    margin-left: 0;
    align-items: flex-start;
  }

  .basic-information-remove-button {
    font-size: 12px;
    text-decoration-line: underline;
    color: #C4C4C4;
    margin-left: 10px;

    &:focus {
      box-shadow: none;
    }
  }

  .basic-information-textarea {
    margin-top: 5px;
    width: 285px;
    height: 300px;
  }

  .basic-information-note-text {
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    padding: 5px 10px;
    width: 230px;
    margin-left: 0;
    margin-top: 5px;
  }

  .basic-information-benefits {
    text-align: left;

    &__title {
      margin-bottom: 5px;
    }
  }

  .basic-information-employment-types__title {
     margin-bottom: 5px;
  }

  .basic-information-employment-types__subtitle {
    font-size: 10px;
    margin-bottom: 15px;
  }

  .basic-information-button {
    margin: 3px;
  }

  .basic-information-details {
    margin-top: 40px;
  }

  .basic-information-errors {
    color: #FF3000;
    font-size: 12px;
    margin: -15px 0 5px 0;
  }
</style>

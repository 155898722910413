<template>
  <div>
    <CInput
      :invalid-feedback="invalidFeedback"
      :is-valid="isValid"
      :value="value"
      :label="label"
      :type="type"
      :name="name"
      :min="min"
      :max="max"
      :autocomplete="autocomplete"
      :class="{ 'has-errors': hasErrors }"
      :placeholder="placeholder"
      :required="required"
      @input="updateValue($event)"
      @keydown="$emit('keydown', $event)"
    />
    <div v-if="hasErrors" class="input-error"> {{ errors.join(', ') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    },
    invalidFeedback: {
      type: String,
      default: '',
    },
    isValid: {
      type: Function,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    hasErrors() {
      return this.errors && this.errors.length > 0;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .underline-input {
    .form-group {
      width: 350px;

      ::v-deep input[type="text"].form-control,
      ::v-deep input[type="password"].form-control {
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #000000;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .border-input {
    .form-group {
      width: 180px;

      ::v-deep input[type="text"].form-control,
      ::v-deep input[type="password"].form-control {
        border: 1px solid #E5E5E5;
        border-radius: 10px;
      }

      ::v-deep label {
        font-size: 10px;
        line-height: 12px;
      }

      &.has-errors {
        ::v-deep input[type="text"].form-control,
        ::v-deep input[type="password"].form-control {
          border-color: #FF3000;
        }
      }
    }
  }

  .search-input {
    .form-group {
      ::v-deep input[type="text"].form-control {
        background: url('../../../assets/images/search.svg') no-repeat 15px;
        border-radius: 6px;
        height: 45px;
        padding-left: 45px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .input-error {
    color: #FF3000;
    font-size: 12px;
    margin: -15px 0 0 5px;
  }
</style>

<template>
  <div class="change-password-form-wrapper">
    <div class="change-password-form-header">
      {{ $t(`settings.your_data`)}}
    </div>
    <div class="change-password-form-subheader">
      {{ $t(`settings.your_data_description`)}}
    </div>

    <form class="row" @submit.prevent="submit">
      <CustomInput
        v-model="currentPassword"
        :label="$t('settings.current_password.label')"
        :placeholder="$t('settings.current_password.placeholder')"
        :errors="errors.currentPassword"
        type="password"
        class="border-input"
      />

      <CustomInput
        v-model="password"
        :label="$t('settings.password.label')"
        :placeholder="$t('settings.password.placeholder')"
        :errors="errors.password"
        type="password"
        class="border-input"
      />

      <CustomInput
        v-model="passwordConfirmation"
        :label="$t('settings.password_confirmation.label')"
        :placeholder="$t('settings.password_confirmation.placeholder')"
        :errors="errors.passwordConfirmation"
        type="password"
        class="border-input"
      />

      <CButton
        type="submit"
        color="primary-dark"
        shape="pill"
        :disabled="loading"
        class="text-white change-password-form-button"
      >
        {{ $t('common.confirm') }}
      </CButton>
    </form>
  </div>
</template>

<script>
import CustomInput from '../custom/CustomInput.vue'
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'forms/changePassword/getField',
  mutationType: 'forms/changePassword/updateField',
});
export default {
  components: {
    CustomInput,
  },
  computed: {
    ...mapFields([
      'form.currentPassword',
      'form.password',
      'form.passwordConfirmation',
    ]),
    ...mapState({
      errors: state => state.forms.changePassword.errors,
      loading: state => state.forms.changePassword.loading,
      success: state => state.forms.changePassword.success,
    }),
  },
  created() {
    this.$store.commit('forms/changePassword/init');
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      await this.$store.dispatch('forms/changePassword/change');

      if (this.success) {
        this.displayNotice();
        this.$store.commit('forms/changePassword/init');
      }
    },
    displayNotice() {
      this.$store.dispatch('toaster/display', {
        messages: [{ message: this.$t('settings.password_changed') }],
        autoHide: 5000,
      });
    },
  },
};
</script>


<style lang="scss" scoped>
  .change-password-form-wrapper {
    margin-top: 50px;
  }

  .change-password-form-header {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .change-password-form-subheader {
    margin-bottom: 30px;
  }

  .row {
    padding: 0 20px;
  }

  .border-input {
    margin-right: 10px;
  }

  .change-password-form-button {
    height: 34px;
    width: 100px;
    margin-top: 25px;
    margin-left: 20px;
  }
</style>

import _ from 'lodash';
import { basicFormAction, basicFormStore, basicFormState } from './basic';
import teamMembersRepository from '../../repositories/team_members_repository';

const formState = () => ({
  form: {
    password: '',
    passwordConfirmation: '',
  },
});

const mutations = {
  init(state) {
    _.assign(state, basicFormState(), formState());
  },
};

const actions = {
  async acceptInvitation(context, token) {
    await basicFormAction(context, async () => {
      await teamMembersRepository.acceptInvitation( _.merge(context.state.form, { token }));
    });
  },
};

export default _.defaultsDeep({
  state: basicFormState(),
  actions,
  mutations,
}, basicFormStore);

<template>
  <div class="submit-bar">
    <div v-if="errors.publish" class="submit-bar-errors">
      {{ errors.publish }}
    </div>
    <ConfirmationDialog
      :showModal="showModal"
      :event="event"
      @modalClosed="showModal = false"
      @confirmed="proceed($event)" />
    <CButton
      color="primary-light"
      variant="ghost"
      :disabled="loading"
      class="submit-bar-button"
      @click="showDialog('reject')"
    >
      {{ $t('companies.job_offers.job_offer_form.delete') }}
    </CButton>
    <CButton
      color="primary-dark"
      shape="pill"
      :disabled="loading"
      class="submit-bar-button submit-bar-button--light"
      @click="submit(false)"
    >
      {{ $t('companies.job_offers.job_offer_form.save_draft') }}
    </CButton>
    <CButton
      color="primary-dark"
      shape="pill"
      :disabled="loading"
      class="text-white submit-bar-button"
      @click="showDialog('publish')"
    >
      {{ $t('companies.job_offers.job_offer_form.publish') }}
    </CButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmationDialog from './ConfirmationDialog.vue';

export default {
  data() {
    return {
      showModal: false,
      event: '',
    }
  },
  components: {
    ConfirmationDialog,
  },
  computed: {
    ...mapState({
      loading: state => state.forms.jobOffer.loading,
      errors: state => state.forms.jobOffer.errors,
      success: state => state.forms.jobOffer.success,
    }),
  },
  methods: {
    submit(publish) {
      if (this.$route.params.id) {
        this.updateJobOffer(publish);
      } else {
        this.createJobOffer(publish);
      }
    },
    async createJobOffer(publish) {
      await this.$store.dispatch('forms/jobOffer/create', { jobOfferDraftId: null, publish });

      if (this.success) {
        if (!publish) {
          this.$store.commit('jobOffers/setCurrentTab', 'drafts');
        }
        this.$router.push({ name: 'job_offers'});
      }
    },
    async updateJobOffer(publish) {
      await this.$store.dispatch('forms/jobOffer/create', { jobOfferDraftId: this.$route.params.id, publish });

      if (this.success) {
        this.$router.push({ name: 'job_offers'});
      }
    },
    reject() {
      if (this.$route.params.id) {
        this.deleteJobOfferDraft();
      } else {
        this.$router.push({ name: 'job_offers'});
      }
    },
    async deleteJobOfferDraft() {
      await this.$store.dispatch('forms/jobOffer/deleteDraft', { draftId: this.$route.params.id });

      this.$store.dispatch('toaster/display', {
        messages: [{ message: this.$t('companies.job_offers.job_offer_form.deleted') }],
        autoHide: 5000,
      });
      this.$router.push({ name: 'job_offers'});
    },
    showDialog(event) {
      this.event = event;
      this.showModal = true;
    },
    proceed(event) {
      if (event === 'reject') {
        this.reject();
      } else {
        this.submit(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .submit-bar-button {
    margin: 0 5px;
    border-radius: 8px;

    &--light {
      background: #fff;
      color: #70738E;
    }
  }

  .submit-bar {
    background-color: #F5F5F7;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 190px;
    width: 100%;
    z-index: 2;
  }

  .submit-bar-errors {
    color: #FF3000;
    font-size: 12px;
    padding: 10px;
  }
</style>

<template>
  <CustomModal :showModal="showModal" size="lg" @modalClosed="closeModal">
    <template slot="body-wrapper">
      <div class="hire-modal-body">
        <span class="hire-modal-span">{{ $t('companies.match.fill_the_data') }} </span>
        <CustomInput
          v-model="salary"
          type="number"
          min="0"
          :invalid-feedback="$t('common.invalid_feedback')"
          :is-valid="isNumber"
          :label="$t('companies.match.salary')"
          class="hire-modal-input" />
        <CustomInput
          v-model="startDate"
          :label="$t('companies.match.start_date')"
          type="date"
          class="hire-modal-input" />
      </div>
    </template>
    <template slot="footer">
      <CButton
        :disabled="!(isNumber(salary) && startDate)"
        color="primary-dark"
        shape="pill"
        @click="confirmMatch"
      >
        {{ $t('common.confirm') }}
      </CButton>
      <CButton
        shape="pill"
        class="hire-modal-confirm"
        @click="closeModal"
      >
        {{ $t('common.cancel') }}
      </CButton>
    </template>
  </CustomModal>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import CustomInput from '../custom/CustomInput.vue';
import CustomModal from '../custom/CustomModal.vue';

const { mapFields } = createHelpers({
  getterType: 'forms/hire/getField',
  mutationType: 'forms/hire/updateField',
});

export default {
  components: {
    CustomInput,
    CustomModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapFields([
      'form.salary',
      'form.startDate',
    ]),
    ...mapState({
      loading: state => state.forms.hire.loading,
      success: state => state.forms.hire.success,
    }),
  },
  created() {
    this.$store.commit('forms/hire/init');
  },
  methods: {
    closeModal() {
     this.$emit('modalClosed');
    },
    async confirmMatch() {
      if (this.loading) {
        return;
      }

      await this.$store.dispatch('forms/hire/hire', {
        id: this.$route.params.id
      });

      if (this.success) {
        this.closeModal();
        await this.$store.dispatch('recruitmentProcesses/show', {
          id: this.$route.params.id
        });
      }
    },
    isNumber(value) {
      if(_.isEmpty(value)) {
        return false;
      }
      return !_.isNaN(_.toNumber(value.replace(',', '.')));
    },
  },
};
</script>

<style lang="scss" scoped>
  .hire-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 20px 0;
  }

  .hire-modal-span {
    font-size: 18px;
  }

  .hire-modal-input {
    margin-top: 20px;
    width: 289px;
  }

  ::v-deep .modal-content {
    font-size: 14px;
    text-align: left;
  }

  ::v-deep .modal-footer {
    margin-bottom: 30px;
  }

  .hire-modal-confirm {
    border: 1px solid #F7A123;
    color: #F7A123;
    margin-left: 8px;
  }
</style>
